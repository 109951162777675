import { Button } from "@soltivo/draw-a-line";
import { ArrowLeft } from "@soltivo/draw-a-line/core/components/icons";
import { FC } from "react";
import styles from "./footer.module.scss";

interface ActionFooterProps {
    actions: {
        backward: {
            disabled?: boolean;
            loading?: boolean;
            text?: string;
            onClick: () => void;
        };
        forward: {
            disabled?: boolean;
            loading?: boolean;
            text?: string;
            type?: "destructive" | "default";
            onClick: () => void;
        };
    };
}

const ActionFooter: FC<ActionFooterProps> = (props) => {
    const {
        actions: { backward, forward },
    } = props;

    return (
        <div className={`${styles.footer_container} ${styles.footer_container_float}`}>
            <div className={styles.footer_navigation}>
                <Button
                    variant="geyser-900"
                    padding={false}
                    border={false}
                    className={styles.button}
                    outline
                    onClick={backward.onClick}
                    loading={backward.loading && { keepContent: true }}
                    disabled={backward.disabled || backward.loading}
                    aria-label={backward.text || "Move backward"}
                    aria-busy={backward.loading || false}
                >
                    <ArrowLeft /> {backward.text || "Back"}
                </Button>
                <Button
                    loading={forward.loading && { keepContent: true }}
                    variant={forward?.type === "destructive" ? "valencia-800" : "bluemine-900"}
                    className={styles.button}
                    onClick={forward.onClick}
                    disabled={forward.disabled || forward.loading}
                    aria-label={forward.text || "Move forward"}
                    aria-busy={forward.loading || false}
                >
                    {forward.text || "Next"}
                </Button>
            </div>
        </div>
    );
};

export default ActionFooter;
