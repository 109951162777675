import type { FC } from "react";
import { EventAttributes, createEvent } from "ics";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { download, getServiceInfo, getUserInfo } from "@/helpers/helpers";
import { Button } from "@soltivo/draw-a-line";
import { CalendarToday24Px } from "@soltivo/draw-a-line/core/components/icons";
import { PublicAppointment } from "@soltivo/types";
import styles from "./footer.module.scss";

interface AppointmentFooterProps {
    appointment: PublicAppointment | null;
}

const AppointmentFooter: FC<AppointmentFooterProps> = ({ appointment }) => {
    const navigate = useNavigate();

    /**
     * @description export calendar event
     */
    const createEventInCalendar = () => {
        if (!appointment) return;
        const { start, end, userInfo, locationInfo, serviceInfo } = appointment;

        const yearStart = moment(start).year();
        // Moment returns 0 to 11, the calendar need 1 to 12
        const monthStart = moment(start).month() + 1;
        const bookingDateStart = moment(start).date();
        const startHour = moment(start).hour();
        const startMinutes = moment(start).minute();

        const yearEnd = moment(end).year();
        // Moment returns 0 to 11, the calendar need 1 to 12
        const monthEnd = moment(end).month() + 1;
        const bookingDateEnd = moment(end).date();
        const endHour = moment(end).hour();
        const endMinutes = moment(end).minute();

        const event: EventAttributes = {
            title: `${getServiceInfo(serviceInfo).title} with ${getUserInfo(userInfo).firstName}`,
            alarms: [
                {
                    action: "display",
                    trigger: {
                        minutes: 15,
                        before: true,
                    },
                },
                {
                    action: "display",
                    trigger: {
                        minutes: 30,
                        before: true,
                    },
                },
                {
                    action: "display",
                    trigger: {
                        hours: 1,
                        before: true,
                    },
                },
            ],
            busyStatus: "BUSY",
            start: [yearStart, monthStart, bookingDateStart, startHour, startMinutes],
            end: [yearEnd, monthEnd, bookingDateEnd, endHour, endMinutes],
            location: locationInfo?.address || "",
        };

        const result = createEvent(event);

        if (result?.error) {
            console.error(result?.error);
            return;
        }

        download("appointment.ics", result?.value || "");
    };

    return (
        <div className={styles.footer_container}>
            <Button
                variant="bluemine-900"
                className={styles.button_full}
                aria-label="Add to calendar"
                outline
                border
                onClick={createEventInCalendar}
            >
                <CalendarToday24Px width="18px" height="18px" /> Add to your calendar
            </Button>
            <hr />
            <div className={styles.footer_actions}>
                <Button
                    aria-label="Edit information"
                    onClick={() => navigate("edit")}
                    className={styles.button_full}
                    variant="bluemine-900"
                >
                    Edit Info
                </Button>
                <Button
                    aria-label="Reschedule appointment"
                    onClick={() => navigate("reschedule")}
                    className={styles.button_full}
                    variant="bluemine-900"
                >
                    Reschedule
                </Button>
                <Button
                    aria-label="Cancel appointment"
                    onClick={() => navigate("cancel")}
                    className={styles.button_full}
                    variant="valencia-800"
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AppointmentFooter;
