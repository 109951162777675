import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { BookingContext } from "@/reducer/booking.context";
import Employees from "@/components/employee-picker/EmployeesPicker";
import Confirmation from "@/components/confirmation/Confirmation";
import { Route, Routes, useMatch, useNavigate, useSearchParams } from "react-router-dom";
import QuickDates from "@/components/quick-dates/QuickDates";
import ActionFooter from "@/components/footers/actions";
import DatePicker from "@/components/date-picker/DatePicker";
import TimePicker from "@/components/time-picker/TimePicker";
import { SEARCH_PARAMS } from "@/helpers/constants";
import { bookingCreateBooking } from "@/reducer/actions";
import { isValidEmail } from "@/helpers/helpers";
import Categories from "@/components/category-picker/CategoryPicker";
import Services from "@/components/service-picker/ServicePicker";
import Locations from "@/components/location-picker/Locations";

/** Page used to create appointments */
const CreateAppointment = () => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, form, submittingBooking } = state;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [canMoveForward, setCanMoveForward] = useState(false);

    const isCategories = useMatch("/:orgId/");
    const isServices = useMatch("/:orgId/services");
    const isLocations = useMatch("/:orgId/locations");
    const isEmployees = useMatch("/:orgId/employees");
    const isQuickDates = useMatch("/:orgId/date");
    const isCalendar = useMatch("/:orgId/calendar");
    const isSlots = useMatch("/:orgId/slots");
    const isInfo = useMatch("/:orgId/info");

    const start = searchParams.get(SEARCH_PARAMS.start);
    const end = searchParams.get(SEARCH_PARAMS.end);
    const date = searchParams.get(SEARCH_PARAMS.date);

    const serviceId = searchParams.get(SEARCH_PARAMS.service);
    const locationId = searchParams.get(SEARCH_PARAMS.location);
    const userId = searchParams.get(SEARCH_PARAMS.employee);

    const moveTo = (where: string) => {
        navigate(`${where}?${searchParams.toString()}`, { relative: "path" });
    };

    const handleForward = () => {
        if (!canMoveForward) return;
        if (isCategories) moveTo("services");
        if (isServices) moveTo("../locations");
        if (isLocations) moveTo("../employees");
        if (isEmployees) moveTo("../date");
        if (isQuickDates) moveTo("../slots");
        if (isCalendar) moveTo("../slots");
        if (isSlots) moveTo("../info");
        if (isInfo) return onSubmit();

        setCanMoveForward(false);
    };

    const handleBackward = () => {
        if (isCategories) return;
        if (isServices) moveTo("..");
        if (isLocations) moveTo("../services");
        if (isEmployees) moveTo("../locations");
        if (isQuickDates) moveTo("../employees");
        if (isCalendar) moveTo("../date");
        if (isSlots) moveTo("../date");
        if (isInfo) moveTo("../slots");
    };

    const onSubmit = () => {
        const formIsValid = isValidEmail(form.email) || form.firstName || form.phoneNumber || form.lastName;
        if (!formIsValid) return;

        if (!date || !start || !end || !serviceId || !locationId || !userId) {
            return navigate("..");
        }

        const data = {
            end,
            start,
            userId: userId,
            serviceId: serviceId,
            locationId: locationId,
            customerTimezone: moment.tz.guess() || "",
            entityInfo: {
                email: form.email,
                firstName: form.firstName,
                lastName: form.lastName,
                phoneNumber: form.phoneNumber,
            },
        };

        dispatch(bookingCreateBooking(data));
    };

    useEffect(() => {
        if (appointment) {
            const url = new URL(appointment.link);
            navigate(url.pathname);
        }
    }, [appointment]);

    return (
        <>
            <Routes>
                <Route path="/" element={<Categories setCanMoveForward={setCanMoveForward} />} />
                <Route path="/services" element={<Services setCanMoveForward={setCanMoveForward} />} />
                <Route path="/locations" element={<Locations setCanMoveForward={setCanMoveForward} />} />
                <Route path="/employees" element={<Employees setCanMoveForward={setCanMoveForward} />} />
                <Route path="/date" element={<QuickDates setCanMoveForward={setCanMoveForward} />} />
                <Route path="/calendar" element={<DatePicker setCanMoveForward={setCanMoveForward} />} />
                <Route path="/slots" element={<TimePicker setCanMoveForward={setCanMoveForward} />} />
                <Route path="/info" element={<Confirmation setCanMoveForward={setCanMoveForward} />} />
            </Routes>

            <ActionFooter
                actions={{
                    backward: {
                        onClick: handleBackward,
                        disabled: !!isCategories,
                    },
                    forward: {
                        onClick: handleForward,
                        text: isInfo ? "Book" : "Next",
                        loading: submittingBooking,
                        disabled: !canMoveForward,
                    },
                }}
            />
        </>
    );
};

export default CreateAppointment;
