import { memo } from "react";
import { Category } from "@/types";
import Item from "../item/Item";

interface CategoryItemProps {
    category: Category;
    onSelect: (id: string) => void;
    selected: boolean;
    tabIndex?: number;
}

const CategoryItem: React.FC<CategoryItemProps> = memo(({ category, onSelect, selected }) => (
    <Item id={category.id} title={category.name} onSelect={onSelect} selected={selected} />
));

export default CategoryItem;
