import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@/helpers/constants";
import ItemSkeleton from "@/components/item/ItemSkeleton";
import Header from "@/components/header/Header";
import { bookingChangeState, bookingGetCategoryServices } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import ServiceItem from "../service-item/ServiceItem";
import { Service } from "@/types";
import ItemList from "../item-list/ItemList";

interface InnerListProps {
    services: Service[];
    onSelect: (id: string) => void;
    loadingServices: boolean;
    selectedService?: string;
}
function InnerList(props: InnerListProps) {
    if (props.loadingServices) return [1, 2, 3, 4].map((idx) => <ItemSkeleton key={idx} description />);
    return props.services.map((service: Service) => (
        <ServiceItem
            key={service.serviceId}
            service={service}
            selectedService={props.selectedService}
            onSelect={props.onSelect}
        />
    ));
}

interface ServicesProps {
    setCanMoveForward: React.Dispatch<React.SetStateAction<boolean>>;
}
const Services = (props: ServicesProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { loadingServices, services, app } = state;

    const [selectedService, setSelectedService] = useState<string>();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSelectService = (id?: string) => {
        setSelectedService(id);

        if (!id) return;

        props.setCanMoveForward(true);

        // Only re-update the search params if the service id
        // actually has changed to avoid any re-render and other logic change
        if (searchParams.get(SEARCH_PARAMS.service) !== id) {
            setSearchParams((prev) => {
                prev.delete(SEARCH_PARAMS.location);
                prev.set(SEARCH_PARAMS.service, id);
                return prev.toString();
            });
        }
    };

    const goBack = () => navigate("..");

    useEffect(() => {
        return () => dispatch(bookingChangeState({ services: [] }));
    }, []);

    useEffect(() => {
        const categoryId = searchParams.get(SEARCH_PARAMS.category);
        if (!categoryId) return goBack();
        dispatch(bookingGetCategoryServices({ categoryId }));
    }, [app]);

    useEffect(() => {
        const serviceId = searchParams.get(SEARCH_PARAMS.service);
        onSelectService(services.find((s) => s.serviceId === serviceId)?.serviceId);
        // Better UX, auto select the only available option
        if (services.length === 1) {
            onSelectService(services[0].serviceId);
        }
    }, [services]);

    return (
        <>
            <Header title="Our Services" description="Select a service to book an appointment" />
            <ItemList id="services_list" loading={loadingServices} selected={selectedService}>
                <InnerList
                    services={services}
                    loadingServices={loadingServices}
                    onSelect={onSelectService}
                    selectedService={selectedService}
                />
            </ItemList>
        </>
    );
};

export default Services;
