import { bookingGetBooking, bookingUpdateEntityInfos } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { getEntityInfo } from "@/helpers/helpers";
import { FormControl, InputPhone, InputText } from "@soltivo/draw-a-line";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "@/components/header/Header";
import ActionFooter from "@/components/footers/actions";
import styles from "./page.module.scss";

const CONTENT = {
    title: "Edit information",
    description: "Make sure your information are up to date to make sure we can contact you easily if we need to.",
};

const EditAppointmentInfo = () => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, loadingAppointment, updatingEntityInfos } = state;

    const navigate = useNavigate();
    const { appointmentId } = useParams();

    const [editForm, setEditForm] = useState<{
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
    }>({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
    });

    type formKeys = "firstName" | "lastName" | "email" | "phoneNumber";

    const formLabel = {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phoneNumber: "Phone number",
    };

    const onGoBackClick = () => navigate("..", { relative: "path" });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value,
        });
    };

    const handleEditBookingInfo = () => {
        if (!appointment) return;
        // call update booking infos api
        const { email, phoneNumber, firstName, lastName } = editForm;

        dispatch(
            bookingUpdateEntityInfos({
                bookingId: appointment.id,
                entityInfo: {
                    email,
                    phoneNumber,
                    firstName,
                    lastName,
                },
            })
        );
    };

    useEffect(() => {
        if (!appointmentId) onGoBackClick();
        if (!appointment) {
            return dispatch(
                bookingGetBooking({
                    appointmentId: window.atob(appointmentId || ""),
                })
            );
        }
        const entity = getEntityInfo(appointment.entityInfo);
        setEditForm({
            firstName: entity.firstName,
            lastName: entity.lastName,
            email: entity.email,
            phoneNumber: entity.phoneNumber,
        });
    }, [appointment]);

    return (
        <>
            <div>
                <Header title={CONTENT.title} description={CONTENT.description} />
                <form className={styles.customer_info}>
                    {Object.keys(editForm).map((key, index) => {
                        const formKey = key as formKeys;
                        if (formKey === "phoneNumber") {
                            return (
                                <FormControl
                                    key={index}
                                    labelProps={{ value: formLabel[formKey], htmlFor: "phoneNumber" }}
                                >
                                    <InputPhone
                                        preferredCountries={["us", "ca", "fr"]}
                                        inputProps={{
                                            id: "phoneNumber",
                                            name: "phoneNumber",
                                            type: "text",
                                            required: false,
                                        }}
                                        value={editForm.phoneNumber}
                                        onChange={(_v, _c, _e, formattedValue) => {
                                            setEditForm({ ...editForm, phoneNumber: formattedValue });
                                        }}
                                    />
                                </FormControl>
                            );
                        }

                        const inputLabel = formKey
                            // Split for camelCase
                            .split(/(?<=[a-z])(?=[A-Z])/)
                            .join(" ")
                            .toLowerCase();

                        return (
                            <FormControl key={index} labelProps={{ value: formLabel[formKey], htmlFor: formKey }}>
                                <InputText
                                    id={formKey}
                                    aria-label={inputLabel}
                                    name={formKey}
                                    type="text"
                                    value={editForm[formKey]}
                                    onChange={onChange}
                                    placeholder={formLabel[formKey]}
                                    autoComplete="off"
                                />
                            </FormControl>
                        );
                    })}
                </form>
            </div>
            <ActionFooter
                actions={{
                    backward: {
                        onClick: onGoBackClick,
                    },
                    forward: {
                        text: "Save",
                        loading: updatingEntityInfos,
                        disabled: loadingAppointment || updatingEntityInfos,
                        onClick: handleEditBookingInfo,
                    },
                }}
            />
        </>
    );
};

export default EditAppointmentInfo;
