import apiV2 from "../helpers/api.v2";
import { BookingActions } from "./actions.types";
const _URL = `${process.env.REACT_APP_API_URL}/public-bookings`;

class BookingClass {
    /**
     * @description get available quick dates
     */
    bookingGetQuickDates(payload: BookingActions["bookingGetQuickDates"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v3/quick-dates`, {
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get available days in a month
     */
    bookingGetMonthAvailableDays(payload: {
        userId: string;
        serviceId: string;
        month: string;
        year: string;
        currentDate: string;
        locationId: string;
        currentTime: string;
    }) {
        return apiV2.postRequest(`${_URL}/v3/slots`, {
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get available slots in a date
     */
    bookingGetDateAvailableSlots(payload: BookingActions["bookingGetAvailableSlots"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v3/slots`, {
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get all categories
     */
    bookingGetCategories() {
        return apiV2.getRequest(`${_URL}/categories`);
    }

    /**
     * @description get services inside a category
     */
    bookingGetCategoryServices(payload: { categoryId: string }) {
        return apiV2.getRequest(`${_URL}/v1/services/category/${payload.categoryId}`);
    }

    /**
     * @description get service info
     */
    bookingGetServiceInfos(payload: { serviceId: string }) {
        return apiV2.getRequest(`${_URL}/v1/services/${payload.serviceId}`);
    }

    /**
     * @description get employees inside a service
     */
    getEmployeesInServices(payload: BookingActions["getEmployeesInServices"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v1/employees`, {
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description get service
     */
    bookingGetService(payload: { serviceId: string }) {
        return apiV2.getRequest(`${_URL}/services/${payload.serviceId}`);
    }

    /**
     * @description create a booking
     */
    bookingCreateBooking(payload: BookingActions["bookingCreateBooking"]["payload"]) {
        return apiV2.postRequest(`${_URL}/v2`, {
            data: JSON.stringify(payload),
        });
    }

    /**
     * @description cancel a booking
     */
    bookingCancelBooking(payload: { appointmentId: string; reason?: string }) {
        return apiV2.deleteRequest(`${_URL}/${payload.appointmentId}`, {
            data: JSON.stringify({ cancelingReason: payload.reason || "" }),
        });
    }

    /**
     * @description get booking
     */
    bookingGetBooking(payload: { appointmentId: string }) {
        return apiV2.getRequest(`${_URL}/v1/${payload.appointmentId}`, {
            headers: {
                'cache-control': 'no-store'
            }
        });
    }

    /**
     * @description update booking entity infos
     */
    bookingUpdateEntityInfos(payload: BookingActions["bookingUpdateEntityInfos"]["payload"]) {
        const { bookingId, entityInfo } = payload;
        return apiV2.putRequest(`${_URL}/v1/${bookingId}/entity`, {
            data: JSON.stringify(entityInfo),
        });
    }

    /**
     * @description update booking
     */
    bookingReschedule(payload: BookingActions["bookingReschedule"]["payload"]) {
        const { appointmentId, data } = payload;
        return apiV2.putRequest(`${_URL}/reschedule/${appointmentId}`, {
            data: JSON.stringify(data),
        });
    }
}

export default new BookingClass();
