import { useContext, useEffect, useState } from "react";
import { getEmployeesInServices } from "../../reducer/actions";
import { BookingContext } from "../../reducer/booking.context";
import { Employee } from "../../types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@/helpers/constants";
import ItemSkeleton from "../item/ItemSkeleton";
import Header from "@/components/header/Header";
import EmployeeItem from "../employee-item/EmployeeItem";
import ItemList from "../item-list/ItemList";

interface InnerListProps {
    employees: Employee[];
    selected?: string;
    onSelect: (employeeId: string) => void;
    loading: boolean;
}

function InnerList(props: InnerListProps) {
    if (props.loading) {
        return [1, 2, 3].map((idx) => <ItemSkeleton key={idx} avatar align="start" contentDirection="row" />);
    }
    return props.employees
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map((emp) => (
            <EmployeeItem
                key={emp.employeeId}
                employee={emp}
                selected={props?.selected === emp.employeeId}
                onSelect={props.onSelect}
            />
        ));
}

interface EmployeesProps {
    setCanMoveForward: React.Dispatch<React.SetStateAction<boolean>>;
}
const Employees = (props: EmployeesProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { app, employees, loadingEmployees } = state;

    const [selectedEmployee, setSelectedEmployee] = useState<string>();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSelectEmployee = (employeeId?: string) => {
        setSelectedEmployee(employeeId);

        if (!employeeId) return;

        props.setCanMoveForward(true);

        if (searchParams.get(SEARCH_PARAMS.employee) !== employeeId) {
            setSearchParams((prev) => {
                // Date depends on the selected employee so we must
                // reset the selected date when selecting an employee
                prev.delete(SEARCH_PARAMS.date);

                prev.set(SEARCH_PARAMS.employee, employeeId);
                return prev.toString();
            });
        }
    };

    useEffect(() => {
        const employeeId = searchParams.get(SEARCH_PARAMS.employee);
        // So we maintain user choice across navigation
        onSelectEmployee(employees?.find((e) => e.employeeId === employeeId)?.employeeId);
    }, [employees]);

    useEffect(() => {
        const serviceId = searchParams.get(SEARCH_PARAMS.service);
        const locationId = searchParams.get(SEARCH_PARAMS.location);

        if (!serviceId || !locationId) return navigate("../");

        dispatch(
            getEmployeesInServices({
                serviceId: serviceId,
                locationId: locationId,
            })
        );
    }, [app]);

    return (
        <div>
            <Header title="Our Team" description="Choose who will perform your appointment" />
            <ItemList id="employees_list" loading={loadingEmployees} selected={selectedEmployee}>
                <InnerList
                    employees={employees}
                    loading={loadingEmployees}
                    onSelect={onSelectEmployee}
                    selected={selectedEmployee}
                />
            </ItemList>
        </div>
    );
};

export default Employees;
