import { bookingChangeState, bookingGetQuickDates } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { MOMENT_FORMAT, SEARCH_PARAMS } from "@/helpers/constants";
import { Button } from "@soltivo/draw-a-line";
import { ButtonProps } from "@soltivo/draw-a-line/core/components/buttons/button/button";
import { Calendar } from "@soltivo/draw-a-line/core/components/icons";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DateItem from "../date-item/DateItem";
import ItemSkeleton from "../item/ItemSkeleton";
import Header from "../header/Header";
import ItemList from "../item-list/ItemList";
import styles from "./QuickDates.module.scss";

interface InnerListProps {
    dates: string[];
    selectedDate?: string;
    loadingQuickDates: boolean;
    onSelectDate: (date: string) => void;
}
function InnerList({ dates, onSelectDate, selectedDate, loadingQuickDates }: InnerListProps) {
    if (loadingQuickDates) return [1, 2, 3, 4, 5].map((idx) => <ItemSkeleton key={idx} width="medium" description />);
    return dates.map((date) => (
        <DateItem key={date} date={date} selectedDate={selectedDate} onSelectDate={onSelectDate} />
    ));
}

interface QuickDatesProps {
    setCanMoveForward?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuickDates = (props: QuickDatesProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, quickDates, loadingQuickDates } = state;

    const [selectedDate, setSelectedDate] = useState<string | undefined>();
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const serviceId = appointment?.serviceId || searchParams.get(SEARCH_PARAMS.service);
    const locationId = appointment?.locationId || searchParams.get(SEARCH_PARAMS.location);
    const userId = appointment?.userId || searchParams.get(SEARCH_PARAMS.employee);

    const handleShowCalendar: ButtonProps["onClick"] = (event) => {
        event.preventDefault();
        navigate(`../calendar?${searchParams.toString()}`, { replace: false });
    };

    const handleSelectDate = (date: string) => {
        if (!moment(date, MOMENT_FORMAT.iso, true).isValid()) return;

        setSelectedDate(date);

        props.setCanMoveForward && props.setCanMoveForward(true);

        if (searchParams.get(SEARCH_PARAMS.date) !== date) {
            const selected = moment(date).format(MOMENT_FORMAT.iso);
            setSearchParams((prev) => {
                prev.delete(SEARCH_PARAMS.start);
                prev.delete(SEARCH_PARAMS.end);
                prev.set(SEARCH_PARAMS.date, selected);
                return prev.toString();
            });
        }
    };

    useEffect(() => {
        const date = searchParams.get(SEARCH_PARAMS.date);

        if (date) handleSelectDate(date);

        if (serviceId && locationId && userId) {
            dispatch(
                bookingGetQuickDates({
                    start: moment().utc().format(MOMENT_FORMAT.iso),
                    userId: userId,
                    serviceId: serviceId,
                    locationId: locationId,
                })
            );
        }

        return () => dispatch(bookingChangeState({ quickDates: [] }));
    }, [appointment]);

    return (
        <div>
            <Header title="Select a Date" description="Please select the best date for you." />
            <ItemList id="quickDates_list" loading={loadingQuickDates} selected={selectedDate} flexDirection="row">
                <InnerList
                    dates={quickDates}
                    loadingQuickDates={loadingQuickDates}
                    selectedDate={selectedDate}
                    onSelectDate={date => handleSelectDate(moment(date).format(MOMENT_FORMAT.iso))}
                />
                <Button
                    className={styles.button}
                    onClick={handleShowCalendar}
                    outline
                    border={false}
                    variant="bluemine-900"
                >
                    <Calendar /> Select other dates
                </Button>
            </ItemList>
        </div>
    );
};

export default QuickDates;
