import { Reducer, ReducerState, useReducer } from "react";

function useReducerWithMiddleware<R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>,
    middleware: (state: ReducerState<R>, action: any, dispatch: React.Dispatch<any>) => void
): [React.ReducerState<R>, React.Dispatch<React.ReducerAction<R>>] {
    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchMiddleware = (action: any) => {
        dispatch(action);
        middleware(state, action, dispatch);
    };

    return [state, dispatchMiddleware];
}

export default useReducerWithMiddleware;
