import moment from "moment-timezone";
import { MOMENT_FORMAT } from "@/helpers/constants";
import { FC, memo } from "react";
import Item from "../item/Item";
import { prettyDate } from "@/helpers/helpers";

interface DateItemProps {
    date: string;
    selectedDate?: string;
    onSelectDate: (date: string) => void;
}

const DateItem: FC<DateItemProps> = memo((props) => {
    const { date, onSelectDate, selectedDate } = props;

    const selected = selectedDate === date;

    return (
        <Item
            width="medium"
            onSelect={onSelectDate}
            border={false}
            id={date}
            title={prettyDate(date)}
            description={moment(date).format(MOMENT_FORMAT.weekday)}
            selected={selected}
        />
    );
});

export default DateItem;
