import { useContext, useEffect, useState } from "react";
import Illustration from "@soltivo/draw-a-line/core/assets/illutrations/SteveThink.svg";
import ActionFooter from "@/components/footers/actions";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { BookingContext } from "@/reducer/booking.context";
import { bookingCancelBooking, bookingChangeState, bookingGetBooking } from "@/reducer/actions";
import { Success } from "@/components/success/Success";
import { FormControl, Textarea } from "@soltivo/draw-a-line";
import styles from "./page.module.scss";

const CONTENT = {
    title: "Cancel your appointment?",
    description: "Are you sure you want to cancel your appointment?",
};

function MainContent() {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, loadingAppointment, loadingCancel } = state;

    const [cancelingReason, setCancelingReason] = useState("");

    const navigate = useNavigate();
    const { appointmentId } = useParams();

    const handleFormChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCancelingReason(event.target.value);
    };

    const handleCancel = () => {
        if (!appointment) return;
        dispatch(
            bookingCancelBooking({
                appointmentId: appointment.id,
                cancelingReason,
            })
        );
    };

    useEffect(() => {
        if (!appointment && appointmentId) {
            dispatch(
                bookingGetBooking({
                    appointmentId: window.atob(appointmentId),
                })
            );
        }
    }, [appointment]);

    return (
        <>
            <div>
                <div className={styles.illustration_container}>
                    <img
                        height="250px"
                        src={Illustration}
                        aria-label="A cartoon man with a questioning expression, thinking with his chin on his hand."
                    />
                </div>
                <div className={styles.text_container}>
                    <h1 className={styles.title}>{CONTENT.title}</h1>
                    <h4 className={styles.description}>{CONTENT.description}</h4>
                    <div className={styles.reason_container}>
                        <FormControl labelProps={{ value: "Canceling Reason", htmlFor: "cancelingReason" }}>
                            <Textarea
                                id="cancelingReason"
                                name="cancelingReason"
                                aria-labelledby="cancelingReason"
                                value={cancelingReason}
                                onChange={handleFormChange}
                                placeholder="Canceling Reason"
                                autoComplete="off"
                            />
                        </FormControl>
                    </div>
                </div>
            </div>
            <ActionFooter
                actions={{
                    backward: {
                        onClick: () => navigate("..", { relative: "path" }),
                    },
                    forward: {
                        onClick: handleCancel,
                        text: "Cancel",
                        type: "destructive",
                        loading: loadingCancel,
                        disabled: loadingAppointment,
                    },
                }}
            />
        </>
    );
}

const CancelAppointment = () => {
    const { state, dispatch } = useContext(BookingContext);
    const { redirectTo } = state;

    const navigate = useNavigate();

    useEffect(() => {
        if (!redirectTo) return;
        navigate(redirectTo, { replace: true });
        dispatch(bookingChangeState({ redirectTo: null }));
    }, [redirectTo]);

    return (
        <>
            <Routes>
                <Route path="" element={<MainContent />} />
                <Route path="success" element={<Success />} />
            </Routes>
        </>
    );
};

export default CancelAppointment;
