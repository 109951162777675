export const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const MOMENT_FORMAT = {
    full_date: "LL",
    date_no_year: "DD MMMM",
    date_time: "LL [at] LT",
    time: "LT",
    weekday: "dddd",
    iso: "YYYY-MM-DD",
};

export const SEARCH_PARAMS = {
    date: "date",
    employee: "employee",
    category: "category",
    location: "location",
    service: "service",
    start: "start",
    end: "end",

    favicon: "favicon",
    name: "name",
};

export const LOCAL_STORAGE_KEYS = {
    orgId: "orgId",
};
