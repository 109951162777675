import styles from "./Header.module.scss";

interface HeaderProps {
    title: string;
    description: string;
}
const Header = ({ title, description }: HeaderProps) => {
    return (
        <div className={styles.header_container}>
            <h1 id="box_title" className={styles.title}>{title}</h1>
            <p id="box_description" className={styles.description}>{description}</p>
        </div>
    );
};

export default Header;
