import { memo } from "react";
import { Employee } from "@/types";
import Avatar from "../avatar/Avatar";
import Item from "../item/Item";
import styles from "./EmployeeItem.module.scss";

interface EmployeeItemProps {
    employee: Employee;
    selected: boolean;
    all?: boolean;
    onSelect: (employeeId: string) => void;
}

const EmployeeItem: React.FC<EmployeeItemProps> = memo(({ employee, selected, all, onSelect }) => (
    <Item
        labelStyle={{ flexDirection: "row", justifyContent: "start" }}
        id={employee.employeeId}
        onSelect={onSelect}
        selected={selected}
        title={`${employee.firstName} ${employee?.lastName?.[0]}.`}
    >
        <div className={styles.avatar}>
            <Avatar
                img={
                    employee.avatar
                        ? {
                              src: all ? "" : employee?.avatar,
                              name: employee?.firstName || "-",
                          }
                        : undefined
                }
            />
        </div>
        <div className={styles.content}>
            <h4>{all ? "No preference" : `${employee?.firstName} ${employee?.lastName?.[0]}.`}</h4>
            {all && <span>More availability</span>}
        </div>
    </Item>
));

export default EmployeeItem;
