import React, { useState, useRef } from "react";
import { InputSelect } from "@soltivo/draw-a-line";
import { ArrowRight, ArrowLeft } from "@soltivo/draw-a-line/core/components/icons";
import moment from "moment";
import { monthsShort } from "../../helpers/constants";
import "./Calendar.styles.scss";

export type CalendarPickerProps = {
    dates: number[];
    onChange(date: number): void;
    className?: string;
    fetchDates?(dataString: string): void;
    preselectedDate?: string;
    loading?: boolean;
    rest?: any;
};

const CalendarPicker: React.FC<CalendarPickerProps> = ({
    onChange,
    className,
    dates,
    fetchDates,
    loading,
    preselectedDate,
    ...rest
}) => {
    const currentDate = new Date();
    const calendar = useRef(null);
    const day = currentDate.getDate();

    const years = Array.from(Array(2).keys()).map((i) => currentDate.getFullYear() + i);

    const [month, setMonth] = useState(currentDate.getMonth());
    const [year, setYear] = useState(currentDate.getFullYear());

    const [currentMonth, setCurrentMonth] = useState(monthsShort[month]);
    const [selectedDate, setSelectedDate] = useState<string>(preselectedDate || "");
    const [selectedDay, setSelectedDay] = useState(day);

    const goToNextMonth = () => {
        const nextMonth = month + 1;
        if (nextMonth > 11) {
            setMonth(0);
            setYear(year + 1);
            setCurrentMonth(monthsShort[0]);
            fetchDates && fetchDates(moment(new Date(year + 1, 0, selectedDay, 0, 0, 0, 0)).format("YYYY-MM-DD"));
        } else {
            setMonth(nextMonth);
            setCurrentMonth(monthsShort[nextMonth]);
            fetchDates && fetchDates(moment(new Date(year, nextMonth, selectedDay, 0, 0, 0, 0)).format("YYYY-MM-DD"));
        }
    };

    const goToPrevMonth = () => {
        const previousMonth = month - 1;
        if (previousMonth < 0) {
            setMonth(11);
            setYear(year - 1);
            setCurrentMonth(monthsShort[11]);
            fetchDates && fetchDates(moment(new Date(year - 1, 11, selectedDay, 0, 0, 0, 0)).format("YYYY-MM-DD"));
        } else {
            setMonth(previousMonth);
            setCurrentMonth(monthsShort[previousMonth]);
            fetchDates &&
                fetchDates(moment(new Date(year, previousMonth, selectedDay, 0, 0, 0, 0)).format("YYYY-MM-DD"));
        }
    };

    const handleDateSelection = (day: number, nextMonth?: number) => {
        let m = month;
        if (nextMonth) m = nextMonth;
        const selected = new Date(year, m, day, 0, 0, 0, 0);
        setSelectedDate(moment(selected).format("YYYY-MM-DD"));
        setSelectedDay(day);
        onChange && onChange(selected.getTime());
    };

    const handleYearChanged = (year: string | number | JSX.Element) => {
        if (typeof year !== "number") return;
        setYear(year);
        const selectedDate = new Date(year + "-" + (month + 1) + "-" + day);
        fetchDates && fetchDates(moment(selectedDate).format("YYYY-MM-DD"));
    };

    /**
     * @description get the number of days in a month
     */
    const getDaysInMonth = (month: number) => {
        return new Date(year, month, 0, 0, 0, 0, 0).getDate();
    };

    /**
     * @description check selected date
     * @returns boolean
     */
    const checkIfSelected = (date: number, month: number, year: number) => {
        if (!selectedDate) return false;
        const selectedDateArray = selectedDate.split("-");
        const y = parseInt(selectedDateArray[0]);
        const m = parseInt(selectedDateArray[1]);
        const d = parseInt(selectedDateArray[2]);
        return d === date && year === y && month + 1 === m;
    };

    /**
     * @description check date is disabled
     * @param date string
     * @returns boolean
     */
    const checkIfDisabled = (date: string) => {
        const allowedDates = dates.map(
            (d) => `${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}-${new Date(d).getDate()}`
        );
        return !allowedDates.includes(date);
    };

    // Populate dates into the board
    const populateDates = () => {
        // Get number of days in the month
        let currentMonthDays = getDaysInMonth(month + 1);
        let previousMonthDays = getDaysInMonth(month);

        // Get the starting day of the month 1,2,3,4,5,6 and 0 for sunday
        let firstDay = new Date(year, month, 1, 0, 0, 0, 0).getDay();

        if (firstDay === 0) firstDay = 7;
        let currentMonthDates: any[] = [];
        let previousMonthDates: any[] = [];
        let nextMonthDates: any[] = [];

        // Populate previous month dates
        if (firstDay > 1) {
            previousMonthDates = [...Array(firstDay - 1).keys()]
                .map((el, i) => {
                    return (
                        <div key={i + el} className={`day disabled-date`}>
                            <span>{previousMonthDays - i}</span>
                        </div>
                    );
                })
                .reverse();
        }
        currentMonthDates = [...Array(currentMonthDays).keys()].map((el, i) => {
            const currentDate = new Date();
            let isSelected = checkIfSelected(i + 1, month, year);

            let isToday = false;
            if (
                i + 1 === currentDate.getDate() &&
                year === currentDate.getFullYear() &&
                month === currentDate.getMonth()
            )
                isToday = true;

            let isPassedDate = false;
            const dateString = `${year}-${month + 1}-${i + 1}`;
            if (currentDate.getFullYear() === year && currentDate.getMonth() === month) {
                isPassedDate = currentDate.getDate() > i + 1;
            } else {
                isPassedDate = new Date(dateString).getTime() < currentDate.getTime();
            }

            const disabled = checkIfDisabled(dateString);

            return (
                <div
                    key={i}
                    onClick={() => (!isPassedDate && !disabled ? handleDateSelection(i + 1) : null)}
                    className={`day ${isSelected ? "selected" : ""} ${
                        isPassedDate || disabled ? "disabled-date" : ""
                    } ${isToday ? "current" : ""} ${isToday && disabled ? "current-disabled" : ""}`}
                >
                    <span>{i + 1}</span>
                </div>
            );
        });

        // Check if calendar is full of date the add next month dates at the end
        const remainingCells = 7 - ([...previousMonthDates, ...currentMonthDates].length % 7);
        if (remainingCells < 7) {
            nextMonthDates = [...Array(remainingCells).keys()].map((el, i) => {
                const isSelected = checkIfSelected(i + 1, month + 1, year);
                const disabled = checkIfDisabled(`${year}-${month + 2}-${i + 1}`);

                return (
                    <div
                        key={i + el}
                        onClick={() => (!disabled ? handleDateSelection(i + 1, month + 1) : null)}
                        className={`day ${isSelected ? "selected" : ""} ${
                            disabled ? "disabled-date" : ""
                        } next-month__date`}
                    >
                        <span>{i + 1}</span>
                    </div>
                );
            });
        }

        return [previousMonthDates, currentMonthDates, nextMonthDates];
    };

    return (
        <div
            role="listbox"
            id="calendarDates_list"
            aria-busy={loading}
            className={`calendar-picker--component ${className ? className : ""}`}
            ref={calendar}
            {...rest}
        >
            <div className="calendar-picker">
                <div className="dates">
                    <div className="months__years">
                        <div className="year">
                            <InputSelect
                                options={years.map((item) => ({ value: item }))}
                                placeholder=" "
                                value={`${year}`}
                                name="year"
                                onChange={(e) => handleYearChanged(e.value)}
                            />
                        </div>
                        <div className="month">
                            <div className="arrows prev-mth" onClick={goToPrevMonth}>
                                <ArrowLeft />
                            </div>
                            <div className="mth">{currentMonth}</div>
                            <div className="arrows next-mth" onClick={goToNextMonth}>
                                <ArrowRight />
                            </div>
                        </div>
                    </div>
                    <div className="days week-days">
                        <h4 className="day__letter">M</h4>
                        <h4 className="day__letter">T</h4>
                        <h4 className="day__letter">W</h4>
                        <h4 className="day__letter">T</h4>
                        <h4 className="day__letter">F</h4>
                        <h4 className="day__letter">S</h4>
                        <h4 className="day__letter">S</h4>
                    </div>
                    <div className="days">{populateDates()}</div>
                </div>
            </div>
        </div>
    );
};

export default CalendarPicker;
