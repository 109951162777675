import { PropsWithChildren } from "react";

interface ItemListProps {
    /** The current item id selected in the list used for Accessibility */
    selected: string | undefined;
    /** If the inner content is loading, mostly used for Accessibility */
    loading: boolean;
    flexDirection?: "row" | "column";
    id?: string;
}

const ItemList: React.FC<PropsWithChildren<ItemListProps>> = ({
    id,
    flexDirection = "column",
    loading,
    selected,
    children,
}) => {
    return (
        <form
            id={id}
            className="items-listing"
            style={{ flexDirection, flexWrap: "wrap" }}
            // Accessibility
            tabIndex={0}
            role="listbox"
            aria-required
            aria-labelledby="box_description"
            aria-multiselectable={false}
            aria-activedescendant={selected}
            aria-busy={loading}
        >
            {children}
        </form>
    );
};

export default ItemList;
