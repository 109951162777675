import { TBookingReducer } from "./reducer";
import types from "./types";

export const bookingLoadApp = () => ({
    type: types.BOOKING_LOAD_APP_REQUEST,
});

export const bookingChangeForm = (payload: { form: any }) => ({
    type: types.CHANGE_FORM,
    payload,
});

export const bookingChangeState = (payload: Partial<TBookingReducer>) => ({
    type: types.BOOKING_CHANGE_STATE,
    payload,
});

export const bookingNextStep = (payload: { step: number }) => ({
    type: types.MOVE_TO_STEP_REQUEST,
    payload,
});

export const redirect = (payload: { path: string | null }) => ({
    type: types.REDIRECT_REQUEST,
    payload,
});

export const bookingGetCategories = () => ({
    type: types.GET_CATEGORIES_REQUEST,
});

export const bookingGetCategoryServices = (payload: { categoryId: string }) => ({
    type: types.GET_CATEGORY_SERVICES_REQUEST,
    payload,
});

export const bookingGetServiceInfos = (payload: { serviceId: string }) => ({
    type: types.GET_SERVICE_INFO_REQUEST,
    payload,
});

export const getEmployeesInServices = (payload: { serviceId: string; locationId: string }) => ({
    type: types.GET_EMPLOYEES_IN_SERVICES_REQUEST,
    payload,
});

interface GetAvailableDates {
    start: string;
    end: string;
    userId: string;
    serviceId: string;
    locationId: string;
}
export const bookingGetAvailableDate = (payload: GetAvailableDates) => ({
    type: types.BOOKING_GET_AVAILABLE_DATE_REQUEST,
    payload,
});

interface GetQuickDates {
    start: string;
    userId: string;
    serviceId: string;
    locationId: string;
}
export const bookingGetQuickDates = (payload: GetQuickDates) => ({
    type: types.BOOKING_GET_QUICK_DATES_REQUEST,
    payload,
});

interface GetSlotsPayload {
    start: string;
    end: string;
    userId: string;
    serviceId: string;
    locationId: string;
}
export const bookingGetAvailableSlots = (payload: GetSlotsPayload) => ({
    type: types.BOOKING_GET_AVAILABLE_SLOTS_REQUEST,
    payload,
});

export const bookingCreateBooking = (payload: {
    userId: string;
    serviceId: string;
    start: string;
    end: string;
    locationId: string;
    entityInfo: {
        email: string;
        phoneNumber: string;
        firstName: string;
        lastName?: string;
    };
}) => ({
    type: types.BOOKING_CREATE_BOOKING_REQUEST,
    payload,
});

export const bookingCancelBooking = (payload: { appointmentId: string; cancelingReason: string }) => ({
    type: types.BOOKING_CANCEL_BOOKING_REQUEST,
    payload,
});

export const bookingGetBooking = (payload: { appointmentId: string }) => ({
    type: types.BOOKING_GET_BOOKING_REQUEST,
    payload,
});

export const bookingReschedule = (payload: {
    appointmentId: string;
    data: {
        userId: string;
        locationId: string;
        serviceId: string;
        start: string;
        end: string;
        entityInfo: {
            entityId: string;
            email: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            type: string;
        };
    };
}) => ({
    type: types.BOOKING_RESCHEDULE_REQUEST,
    payload,
});

export const bookingUpdateEntityInfos = (payload: {
    bookingId: string;
    entityInfo: {
        email: string;
        phoneNumber: string;
        firstName: string;
        lastName?: string;
    };
}) => ({
    type: types.BOOKING_UPDATE_ENTITY_INFOS_REQUEST,
    payload,
});
