import { EntityInfo, ServiceInfo, UserInfo } from "@soltivo/types";
import moment from "moment-timezone";
import { MOMENT_FORMAT } from "./constants";

export const download = (filename: string, content: string) => {
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(content));

    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

export const getServiceInfo = (serviceInfo?: ServiceInfo | string): ServiceInfo => {
    if (typeof serviceInfo === "string" || !serviceInfo) {
        return {
            bufferTime: 0,
            description: "",
            duration: 0,
            equipments: [],
            id: serviceInfo || "",
            price: 0,
            serviceId: "",
            title: "Service not found",
        };
    }
    return serviceInfo;
};

export const getEntityInfo = (entityInfo?: EntityInfo | string): EntityInfo => {
    if (typeof entityInfo === "string" || !entityInfo) {
        return {
            email: "",
            entityId: entityInfo || "",
            firstName: "Not found",
            lastName: "",
            fullname: "",
            manager: "",
            phoneNumber: "",
            type: "lead",
        };
    }
    return entityInfo;
};

export const getUserInfo = (userInfo?: UserInfo | string): UserInfo => {
    if (typeof userInfo === "string" || !userInfo) {
        return {
            firstName: "Not Found",
            lastName: "",
            userId: userInfo || "",
        };
    }
    return userInfo;
};

export const BROWSER_TIMEZONE = moment.tz.guess();

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
    return value !== null && value !== undefined;
};

export const isValidEmail = (email: string) => {
    const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    return emailRegex.test(email);
};

export const prettyDate = (date: string) =>
    moment(date).calendar({
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        nextWeek: MOMENT_FORMAT.date_no_year,
        sameElse: function () {
            // Don't show year if it's the same year... extra info for none
            if (moment(date).year() === moment().year()) return MOMENT_FORMAT.date_no_year;
            return MOMENT_FORMAT.full_date;
        },
    });
