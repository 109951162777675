import { LocationInfo } from "@soltivo/types";
import { memo } from "react";
import Item from "../item/Item";

interface LocationItemProps {
    location: LocationInfo;
    selected: boolean;
    onSelect: (id: string) => void;
}

const LocationItem: React.FC<LocationItemProps> = memo(({ location, selected, onSelect }) => (
    <Item
        id={location.id}
        title={location.name}
        description={location.address}
        onSelect={onSelect}
        selected={selected}
    />
));

export default LocationItem;
