import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@/helpers/constants";
import Header from "@/components/header/Header";
import CategoryItem from "../category-item/CategoryItem";
import ItemSkeleton from "../item/ItemSkeleton";
import { Category } from "@/types";
import { BookingContext } from "@/reducer/booking.context";
import { bookingGetCategories } from "@/reducer/actions";
import ItemList from "../item-list/ItemList";

interface InnerListProps {
    categories: Category[];
    loadingCategories: boolean;
    onSelectCategory: (id: string) => void;
    selectedCategory?: string;
}
function InnerList(props: InnerListProps) {
    if (props.loadingCategories) {
        return [1, 2, 3].map((idx) => <ItemSkeleton key={idx} />);
    }
    return props.categories.map((category, idx) => (
        <CategoryItem
            onSelect={props.onSelectCategory}
            key={idx}
            category={category}
            selected={category.id === props.selectedCategory}
        />
    ));
}

const CONTENT = {
    title: "Our Services",
    description: "Select a service to book an appointment",
};

interface CategoriesProps {
    setCanMoveForward: React.Dispatch<React.SetStateAction<boolean>>;
}
const Categories = (props: CategoriesProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { loadingCategories, app, categories } = state;

    const [selectedCategory, setCategory] = useState<string>();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (categories.length > 0) return;
        dispatch(bookingGetCategories());
    }, [app]);

    const onSelectCategory = (id?: string) => {
        setCategory(id);

        if (!id) return;

        props.setCanMoveForward(true);

        // Only re-update the search params if the category id
        // actually has changed to avoid any re-render and other logic change
        if (searchParams.get(SEARCH_PARAMS.category) !== id) {
            setSearchParams((prev) => {
                if (selectedCategory === id) return prev.toString();
                // If the user change the category, any previously
                // selected information in the searchParams becomes obsolete
                return { [SEARCH_PARAMS.category]: id || "" };
            });
        }
    };

    useEffect(() => {
        const categoryId = searchParams.get(SEARCH_PARAMS.category);
        onSelectCategory(categories.find((c) => c.id === categoryId)?.id);
        // Better UX, auto select the only available option
        if (categories.length === 1) onSelectCategory(categories[0].id);
    }, [categories]);

    return (
        <>
            <Header title={CONTENT.title} description={CONTENT.description} />
            <ItemList id="categories_list" selected={selectedCategory} loading={loadingCategories}>
                <InnerList
                    loadingCategories={loadingCategories}
                    categories={state.categories}
                    onSelectCategory={onSelectCategory}
                    selectedCategory={selectedCategory}
                />
            </ItemList>
        </>
    );
};

export default Categories;
