import React, { useContext, useEffect } from "react";
import moment from "moment";
import { InputPhone, InputText, FormControl } from "@soltivo/draw-a-line";
import {
    bookingChangeForm,
    bookingGetCategories,
    bookingGetServiceInfos,
    getEmployeesInServices,
} from "../../reducer/actions";
import { BookingContext } from "../../reducer/booking.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MOMENT_FORMAT, SEARCH_PARAMS } from "@/helpers/constants";
import { isValidEmail, prettyDate } from "@/helpers/helpers";
import Header from "@/components/header/Header";
import InfoItem from "@/components/info-item/InfoItem";
import styles from "./Confirmation.module.scss";

interface ConfirmationProps {
    setCanMoveForward?: React.Dispatch<React.SetStateAction<boolean>>;
}
const Confirmation = (props: ConfirmationProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const {
        form,
        submittingBooking,
        error,
        serviceInfo,
        categories,
        employees,
        loadingService,
        loadingEmployees,
        loadingCategories,
    } = state;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const start = searchParams.get(SEARCH_PARAMS.start);
    const end = searchParams.get(SEARCH_PARAMS.end);

    const locationId = searchParams.get(SEARCH_PARAMS.location);
    const categoryId = searchParams.get(SEARCH_PARAMS.category);
    const serviceId = searchParams.get(SEARCH_PARAMS.service);
    const employeeId = searchParams.get(SEARCH_PARAMS.employee);

    const location = serviceInfo?.locations.find((l) => l.id === locationId);
    const category = categories?.find((c) => c.id === categoryId);
    const employee = employees?.find((e) => e.employeeId === employeeId);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            bookingChangeForm({
                form: {
                    [e.target.name]: e.target.value,
                },
            })
        );
    };

    const onPhoneNumberChanged = (value: string) => {
        dispatch(
            bookingChangeForm({
                form: {
                    phoneNumber: value,
                },
            })
        );
    };

    const goBack = () => navigate("..");

    useEffect(() => {
        if (!props.setCanMoveForward) return;
        if (form.phoneNumber && isValidEmail(form.email) && form.lastName && form.firstName) {
            props?.setCanMoveForward(true);
        } else {
            props.setCanMoveForward(false);
        }
    }, [form]);

    useEffect(() => {
        if (!serviceId || !locationId || !employeeId) return goBack();
        if (!serviceInfo) dispatch(bookingGetServiceInfos({ serviceId }));
        if (!employees.length) dispatch(getEmployeesInServices({ serviceId, locationId }));
        if (!categories.length) dispatch(bookingGetCategories());
    }, []);

    return (
        <div>
            <Header
                title="Confirmation"
                description="Almost done! Confirm the date, time and details for which you want your appointment."
            />
            {error?.bookingFailed && <span className="submitted_error">{error.bookingFailed}</span>}
            <div className={styles.container}>
                <div>
                    <InfoItem
                        title="Service"
                        loader={{
                            loading: loadingService || loadingCategories,
                            width: 120,
                        }}
                        description={`${`${category?.name} /` || ""} ${serviceInfo?.title || ""}`}
                    />

                    <InfoItem
                        title="With"
                        loader={{
                            loading: loadingEmployees,
                            width: 120,
                        }}
                        description={`${employee?.firstName} ${employee?.lastName[0]}.`}
                    />

                    <InfoItem
                        title="Location"
                        loader={{
                            loading: loadingService,
                            width: 120,
                        }}
                        description={location?.address}
                    />

                    <InfoItem title="Date" description={prettyDate(start || "")} />
                    <InfoItem
                        title="Time"
                        description={`${moment(start).format(MOMENT_FORMAT.time)} to ${moment(end).format(
                            MOMENT_FORMAT.time
                        )}`}
                    />
                </div>
                <form className={styles.form}>
                    <FormControl labelProps={{ htmlFor: "firstName", value: "First Name" }}>
                        <InputText
                            id="firstName"
                            name={"firstName"}
                            value={form.firstName}
                            disabled={submittingBooking}
                            onChange={onChange}
                            placeholder={"First Name"}
                            autoComplete="off"
                        />
                    </FormControl>

                    <FormControl labelProps={{ htmlFor: "lastName", value: "Last Name" }}>
                        <InputText
                            id="lastName"
                            name={"lastName"}
                            value={form.lastName}
                            disabled={submittingBooking}
                            onChange={onChange}
                            placeholder={"Last Name"}
                            autoComplete="off"
                        />
                    </FormControl>

                    <FormControl labelProps={{ htmlFor: "phoneNumber", value: "Phone number" }}>
                        <InputPhone
                            disabled={submittingBooking}
                            preferredCountries={["ca", "us", "fr"]}
                            inputProps={{
                                id: "phoneNumber",
                                name: "phoneNumber",
                                required: true,
                            }}
                            value={form.phoneNumber}
                            onChange={(_v, _c, _e, formattedValue) => onPhoneNumberChanged(formattedValue)}
                        />
                    </FormControl>

                    <FormControl labelProps={{ htmlFor: "email", value: "Email" }}>
                        <InputText
                            id="email"
                            name="email"
                            type="email"
                            value={form.email}
                            disabled={submittingBooking}
                            onChange={onChange}
                            placeholder={"Email"}
                        />
                    </FormControl>
                </form>
            </div>
        </div>
    );
};

export default Confirmation;
