import { BookingContext } from "@/reducer/booking.context";
import { MOMENT_FORMAT, SEARCH_PARAMS } from "@/helpers/constants";
import moment from "moment";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../header/Header";
import styles from "./ConfirmReschedule.module.scss";

export const ConfirmReschedule = () => {
    const { state } = useContext(BookingContext);
    const { appointment } = state;
    const [searchParams] = useSearchParams();

    return (
        <div>
            <Header title="Confirm Reschedule" description="Please confirm the reschedule of your appointment" />
            <div className={styles.reschedule_confirmation_container}>
                <div className={styles.current}>
                    <h5 className="text-geyser-900">Current</h5>
                    <ul className={styles.date_time_container}>
                        <li>
                            <p className={styles.title}>Date</p>
                            <p>{moment(appointment?.start).format(MOMENT_FORMAT.full_date)}</p>
                        </li>
                        <li>
                            <p className={styles.title}>Time</p>
                            <p>
                                {moment(appointment?.start).format(MOMENT_FORMAT.time)} to{" "}
                                {moment(appointment?.end).format(MOMENT_FORMAT.time)}
                            </p>
                        </li>
                    </ul>
                </div>

                <div className={styles.reschedule}>
                    <h5 className="text-geyser-900">Reschedule</h5>
                    <ul className={styles.date_time_container}>
                        <li>
                            <p className={styles.title}>Date</p>
                            <p>{moment(searchParams.get(SEARCH_PARAMS.start)).format(MOMENT_FORMAT.full_date)}</p>
                        </li>
                        <li>
                            <p className={styles.title}>Time</p>
                            <p>
                                {moment(searchParams.get(SEARCH_PARAMS.start)).format(MOMENT_FORMAT.time)} to{" "}
                                {moment(searchParams.get(SEARCH_PARAMS.end)).format(MOMENT_FORMAT.time)}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
