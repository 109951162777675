// import authClass from "../applications/auth/redux/auth/saga/auth.class";

type Method = "GET" | "POST" | "PUT" | "DELETE";
type Data =
    | string
    | Blob
    | ArrayBufferView
    | ArrayBuffer
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | null
    | undefined;

interface Options {
    method?: Method;
    data?: Data;
    useOrg?: boolean;
    useResponseType?: "text" | "json" | "formData" | "blob" | "arrayBuffer";
    headers?: {
        "content-type"?: "application/json" | "application/x-www-form-urlencoded" | "multipart/form-data" | string;
        "cache-control"?: RequestCache;
        authorization?: string;
        orgid?: string;
    };
}

interface Error {
    domain: string;
    field: string;
    message: string;
    reason: string;
}

export interface ErrorResponseV2 {
    message?: string;
    error: {
        code: number;
        errors?: Array<Error>;
        message?: string;
    };
}

const defaultOptions: Options = {
    useOrg: true,
    useResponseType: "json",
};

class ApiV2 {
    get UnknownErroMessage() {
        return "Something went wrong, please contact soltivo team.";
    }

    async request(
        method: Method,
        url: string,
        options: Options = {
            useOrg: true,
        }
    ): Promise<any> {
        const orgId: string | null = localStorage.getItem("orgId");

        const { headers, ...restOptions } = options;

        const _options: Options = {
            ...defaultOptions,
            ...restOptions,
        };

        const _headers = new Headers();
        _headers.append("content-type", headers?.["content-type"] ? headers["content-type"] : "application/json");
        _headers.append("cache-control", headers?.["cache-control"] || "force-cache");

        if (_options.useOrg) {
            if (!orgId) throw new Error("organization id not defined.");
            _headers.append("orgid", atob(orgId));
        }

        if (!window.fetch) {
            throw new Error("Your browser does not support fetch.");
        }

        let response = await fetch(url, {
            cache: headers?.["cache-control"] || "force-cache",
            method: method,
            headers: _headers,
            body: options.data,
        });

        if (!response.ok) {
            throw await response.json();
        }

        switch (_options.useResponseType) {
            case "text":
                return await response.text();
            case "arrayBuffer":
                return await response.arrayBuffer();
            case "json":
                return await response.json();
            case "blob":
                return await response.blob();
            case "formData":
                return await response.formData();
            default:
                return response;
        }
    }

    /**
     * @description transform api errors messages to string.
     */
    stringifyErrors(errorResponse: ErrorResponseV2, code?: number | Array<number>): string | undefined {
        try {
            if (typeof code !== "number" && code) {
                return (
                    code
                        .map((c) => {
                            if (errorResponse?.error?.code === c) {
                                const stringErrors = errorResponse.error.errors?.map(
                                    (error) => `${error.field || ""} ${error.message}`
                                );
                                return stringErrors?.toString().replace(/,/g, "\n\n") || this.UnknownErroMessage;
                            }
                        })
                        ?.toString()
                        .replace(/,/g, "") || this.UnknownErroMessage
                );
            } else if (errorResponse.error.code === code) {
                const stringErrors = errorResponse.error.errors?.map(
                    (error) => `${error.field || ""} ${error.message}`
                );
                return stringErrors?.toString().replace(/,/g, "") || this.UnknownErroMessage;
            } else {
                const stringErrors = errorResponse.error?.errors?.map(
                    (error) => `${error.field || ""} ${error.message}`
                );
                return stringErrors?.toString().replace(/,/g, "") || this.UnknownErroMessage;
            }
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * @description get request
     */
    getRequest(url: string, options?: Options): Promise<any> {
        return this.request("GET", url, options);
    }

    /**
     * @description post request
     */
    postRequest(url: string, options?: Options): Promise<any> {
        return this.request("POST", url, options);
    }

    /**
     * @description put request
     */
    putRequest(url: string, options?: Options): Promise<any> {
        return this.request("PUT", url, options);
    }

    /**
     * @description delete request
     */
    deleteRequest(url: string, options?: Options): Promise<any> {
        return this.request("DELETE", url, options);
    }
}

export { ApiV2 };
export default new ApiV2();
