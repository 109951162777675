import SteveHighFive from "@soltivo/draw-a-line/core/assets/illutrations/SteveHiFive.svg";
import { useContext, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { BookingContext } from "@/reducer/booking.context";
import { bookingGetBooking } from "@/reducer/actions";
import styles from "./Success.module.scss";

export const Success = () => {
    const { state, dispatch } = useContext(BookingContext);
    const isRescheduleFlow = useMatch("/manage/:orgId/:appointmentId/reschedule/success");
    const isCancelFlow = useMatch("/manage/:orgId/:appointmentId/cancel/success");
    const navigate = useNavigate();

    const { appointment } = state;

    const title = (() => {
        if (isRescheduleFlow)
            return "Fantastic! Your appointment has been rescheduled successfully. Hang tight, we're redirecting you!";
        if (isCancelFlow)
            return "All set! Your appointment has been successfully canceled. Thanks for letting us know!";
    })();

    useEffect(() => {
        if (!appointment) return;
        const timeBeforeRedirect = 4_000; // 4 sec
        const redirectAfter = setTimeout(() => {
            if (isRescheduleFlow) {
                const url = new URL(appointment.link);
                navigate(url.pathname, { replace: true });
                dispatch(bookingGetBooking({ appointmentId: appointment.id }));
            }
        }, timeBeforeRedirect);
        return () => clearTimeout(redirectAfter);
    }, []);

    return (
        <div className={styles.success_container}>
            <img alt="" src={SteveHighFive} />
            <h1>{title}</h1>
        </div>
    );
};
