import { bookingChangeState, bookingGetAvailableSlots } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { AvailableSlot } from "@/types";
import { SEARCH_PARAMS } from "@/helpers/constants";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimeItem from "../time-item/TimeItem";
import ItemSkeleton from "../item/ItemSkeleton";
import Header from "../header/Header";
import ItemList from "../item-list/ItemList";
import styles from "./TimePicker.module.scss";
import moment from "moment-timezone";
import { BROWSER_TIMEZONE } from "@/helpers/helpers";

type InnerListProps = {
    slots: AvailableSlot[];
    selectedSlot: string;
    onSelectSlot: (slot: AvailableSlot) => void;
    loadingAvailableSlots: boolean;
};

const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
function InnerList(props: InnerListProps) {
    if (props.loadingAvailableSlots) return skeletons.map((idx) => <ItemSkeleton width="small" key={idx} />);
    return props.slots.map((slot) => (
        <TimeItem
            key={`${slot.start}-${slot.end}`}
            slot={slot}
            selectedSlot={props.selectedSlot}
            onSelectSlot={props.onSelectSlot}
        />
    ));
}

interface TimeProps {
    setCanMoveForward?: React.Dispatch<React.SetStateAction<boolean>>;
}
const TimePicker = (props: TimeProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, availableSlots, loadingAvailableSlots, quickDates } = state;

    const [selectedSlot, setSelectedSlot] = useState("");

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleSelectSlot = (slot: Omit<AvailableSlot, "scheduleId">) => {
        setSelectedSlot(slot.start + slot.end);

        if (props.setCanMoveForward) {
            props.setCanMoveForward(true);
        }

        setSearchParams((prev) => {
            prev.set(SEARCH_PARAMS.start, slot.start || "");
            prev.set(SEARCH_PARAMS.end, slot.end || "");
            return prev.toString();
        });
    };

    useEffect(() => {
        const serviceId = appointment?.serviceId || searchParams.get(SEARCH_PARAMS.service);
        const locationId = appointment?.locationId || searchParams.get(SEARCH_PARAMS.location);
        const userId = appointment?.userId || searchParams.get(SEARCH_PARAMS.employee);
        const selectedDate = searchParams.get(SEARCH_PARAMS.date);

        const start = searchParams.get(SEARCH_PARAMS.start);
        const end = searchParams.get(SEARCH_PARAMS.end);

        if (!selectedDate) return navigate(`../date?${searchParams.toString()}`);

        if (start && end) handleSelectSlot({ start, end });

        if (serviceId && locationId && userId) {
            const selectedDateTime = quickDates.find(d => moment(d).format('YYYY-MM-DD') === selectedDate);
            if(!selectedDateTime) return;
            
            // convert to UTC from the user current time
            const selectedDateUtc = moment.tz(selectedDateTime, BROWSER_TIMEZONE).utc().format('YYYY-MM-DD');

            dispatch(
                bookingGetAvailableSlots({
                    userId: userId,
                    serviceId: serviceId,
                    locationId: locationId,
                    start: selectedDateUtc,
                    end: selectedDateUtc,
                })
            );
        }

        return () => dispatch(bookingChangeState({ availableSlots: [] }));
    }, []);

    return (
        <div className={styles.time_picker_container}>
            <Header title="Select a Time" description="Please select the best time for you." />
            <ItemList id="availableSlots_list" loading={loadingAvailableSlots} selected={selectedSlot} flexDirection="row">
                <InnerList
                    selectedSlot={selectedSlot}
                    loadingAvailableSlots={loadingAvailableSlots}
                    onSelectSlot={handleSelectSlot}
                    slots={availableSlots}
                />
            </ItemList>
        </div>
    );
};

export default TimePicker;
