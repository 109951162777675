import { memo } from "react";
import { Service } from "@/types";
import Item from "../item/Item";

interface ServiceItemProps {
    service: Service;
    onSelect(id: string): void;
    selectedService?: string;
}

const ServiceItem: React.FC<ServiceItemProps> = memo(({ service, onSelect, selectedService }) => {
    const selected = selectedService === service.serviceId;
    const price = (service.price / 100).toLocaleString("en-CA", {
        currency: service?.currency || "CAD",
        style: "currency",
    });
    return (
        <Item
            id={service.serviceId}
            title={service.title}
            description={`${price} + tax`}
            onSelect={onSelect}
            selected={selected}
        />
    );
});

export default ServiceItem;
