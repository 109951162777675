import { Reducer, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { bookingLoadApp } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { BookingReducer, INITIAL_STATE, TBookingReducer, middleware } from "@/reducer/reducer";
import useReducerWithMiddleware from "@/reducer/useCustomReducer";
import CancelAppointment from "@/pages/[orgId]/[appointmentId]/cancel/page";
import EditAppointmentInfo from "./pages/[orgId]/[appointmentId]/edit/page";
import AppointmentView from "@/pages/[orgId]/[appointmentId]/page";
import RescheduleAppointment from "@/pages/[orgId]/[appointmentId]/reschedule/page";
import CreateAppointment from "@/pages/[orgId]/page";
import AppointmentNotFound from "@/pages/[orgId]/[appointmentId]/not-found/page";
import Box from "@/components/box/Box";
import "./app.styles.scss";

function App() {
    const [state, dispatch] = useReducerWithMiddleware<Reducer<TBookingReducer, any>>(
        BookingReducer,
        INITIAL_STATE,
        middleware
    );

    const { app, loadingApp } = state;

    useEffect(() => {
        dispatch(bookingLoadApp());
    }, []);

    const title = (() => {
        const appName = new URLSearchParams(window.location.search).get("name");
        return `Booking ${appName || ""}`;
    })();

    const showLoader = !app || loadingApp;

    if (showLoader) {
        return (
            <div aria-busy className="loading__app">
                <p>{title}</p>
                <div className="loader__bar"></div>
            </div>
        );
    }

    return (
        <BookingContext.Provider value={{ state, dispatch }}>
            <BrowserRouter>
                <Box>
                    <Routes>
                        <Route path={"/:orgId/*"} element={<CreateAppointment />} />

                        {/* Appointment management routes */}
                        <Route path="/manage/:orgId/:appointmentId" element={<AppointmentView />} />
                        <Route path="/manage/:orgId/:appointmentId/not-found" element={<AppointmentNotFound />} />
                        <Route path="/manage/:orgId/:appointmentId/edit" element={<EditAppointmentInfo />} />
                        <Route path="/manage/:orgId/:appointmentId/cancel/*" element={<CancelAppointment />} />
                        <Route path="/manage/:orgId/:appointmentId/reschedule/*" element={<RescheduleAppointment />} />
                    </Routes>
                </Box>
            </BrowserRouter>
        </BookingContext.Provider>
    );
}

export default App;
