import moment from "moment-timezone";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MOMENT_FORMAT } from "@/helpers/constants";
import { BROWSER_TIMEZONE, getServiceInfo, getUserInfo } from "@/helpers/helpers";
import { bookingGetBooking } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import AppointmentFooter from "@/components/footers/footer";
import Header from "@/components/header/Header";
import InfoItem from "@/components/info-item/InfoItem";

const CONTENT = {
    title: "Your Appointment",
    description:
        "Please be sure to take note of the appointment listed below and ensure it is added to your calendar to avoid missing it.",
};

/** Page used to manage appointments */
const AppointmentView = () => {
    const { state, dispatch } = useContext(BookingContext);
    const { app, appointment, error, loadingAppointment } = state;

    const { appointmentId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (error?.appointmentNotFound) return navigate("not-found", { relative: "path" });
        if (!app || !appointmentId || appointment) return;
        dispatch(bookingGetBooking({ appointmentId: window.atob(appointmentId) }));
    }, [app, appointment, error?.appointmentNotFound, appointmentId]);

    const appointmentStart = moment(appointment?.start);
    const appointmentEnd = moment(appointment?.end);

    const appointmentDate = (() => {
        if (loadingAppointment) return "";
        return `${appointmentStart.format(MOMENT_FORMAT.date_time)} till ${appointmentEnd.format(MOMENT_FORMAT.time)} ${
            appointmentEnd.isAfter(appointmentStart, "day") ? "the next day" : ""
        }`;
    })();

    const appointmentService = (() => {
        if (loadingAppointment) return "";
        return getServiceInfo(appointment?.serviceInfo).title;
    })();

    const appointmentEmployee = (() => {
        if (loadingAppointment) return "";
        const firstName = getUserInfo(appointment?.userInfo)?.firstName;
        const lastName = getUserInfo(appointment?.userInfo)?.lastName?.[0];
        return `${firstName} ${lastName + "."}.`;
    })();

    return (
        <>
            <div>
                <Header title={CONTENT.title} description={CONTENT.description} />
                <div>
                    <InfoItem
                        title="When"
                        loader={{
                            loading: loadingAppointment,
                            width: 360,
                        }}
                        description={`${appointmentDate} (${BROWSER_TIMEZONE})`}
                    />

                    <InfoItem
                        title="For"
                        loader={{
                            loading: loadingAppointment,
                            width: 120,
                        }}
                        description={appointmentService}
                    />
                    <InfoItem
                        title="With"
                        loader={{
                            loading: loadingAppointment,
                            width: 160,
                        }}
                        description={appointmentEmployee}
                    />

                    <InfoItem
                        title="Location"
                        loader={{ loading: loadingAppointment, width: 300 }}
                        description={appointment?.locationInfo?.address || "No location"}
                    />
                </div>
            </div>
            <AppointmentFooter appointment={appointment} />
        </>
    );
};

export default AppointmentView;
