import apiV2 from "../helpers/api.v2";
import {
    AvailableDay,
    AvailableMonth,
    AvailableSlot,
    Category,
    NoPreferenceSlot,
    Service,
    Employee,
    TServiceInfo,
} from "../types";
import bookingClass from "./booking.class";
import types from "./types";
import { BROWSER_TIMEZONE, nonNullable } from "../helpers/helpers";
import { PublicAppointment } from "@soltivo/types";
import { matchPath } from "react-router-dom";
import moment from "moment-timezone";
import { redirect } from "./actions";
import { LOCAL_STORAGE_KEYS, SEARCH_PARAMS } from "@/helpers/constants";

type TAction = {
    type: string;
    payload?: any;
    dispatch?: React.Dispatch<{ type: string; payload?: any }>;
};

export type TBookingReducer = {
    app: {
        appName: string;
        appFavicon?: string;
    };
    loadingApp: boolean;
    categories: Category[];
    loadingCategories: boolean;
    services: Service[];
    loadingServices: boolean;
    availableMonths: AvailableMonth[];
    availableDays: AvailableDay[];
    availableSlots: AvailableSlot[];
    noPreferenceSlots: NoPreferenceSlot[];
    loadingQuickDates: boolean;
    loadingEmployees: boolean;
    employees: Employee[];
    quickDates: AvailableDay[];
    loadingAvailableDate: boolean;
    loadingAvailableSlots: boolean;
    loadingService: boolean;
    serviceInfo: TServiceInfo | null;
    step: number;
    form: {
        phoneNumber: string;
        email: string;
        firstName: string;
        lastName: string;
    };
    submittingBooking: boolean;
    loadingCancel: boolean;
    updatingBooking: boolean;
    updatingEntityInfos: boolean;
    appointment: PublicAppointment | null;
    appointmentNotFound: boolean;
    loadingAppointment: boolean;
    bookingFlow?: "create" | "view" | "reschedule";
    redirectTo: string | null;
    error: any;
};

export const INITIAL_STATE: TBookingReducer = {
    app: {
        appName: "Loading...",
        appFavicon: undefined,
    },
    appointment: null,
    appointmentNotFound: false,
    availableDays: [],
    availableMonths: [],
    availableSlots: [],
    categories: [],
    employees: [],
    error: null,
    form: {
        phoneNumber: "",
        email: "",
        firstName: "",
        lastName: "",
    },
    loadingApp: true,
    // If set to true, it infinit load
    // when redirecting after appointment creation
    // so don't change that
    loadingAppointment: false,
    loadingAvailableDate: true,
    loadingAvailableSlots: true,
    loadingCancel: false,
    loadingCategories: true,
    loadingEmployees: true,
    loadingQuickDates: true,
    loadingService: true,
    loadingServices: true,
    noPreferenceSlots: [],
    quickDates: [],
    redirectTo: null,
    serviceInfo: null,
    services: [],
    step: 1,
    submittingBooking: false,
    updatingBooking: false,
    updatingEntityInfos: false,
};

export const BookingReducer = (state = INITIAL_STATE, action: TAction) => {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.payload.form,
                },
            };

        case types.BOOKING_CHANGE_STATE:
            return {
                ...state,
                ...action.payload,
            };

        case types.MOVE_TO_STEP_REQUEST:
            return {
                ...state,
                step: action.payload.step,
            };

        case types.REDIRECT_REQUEST:
            return {
                ...state,
                redirectTo: action.payload.path,
            };

        case types.BOOKING_LOAD_APP_REQUEST:
            return {
                ...state,
                loadingApp: true,
            };

        case types.BOOKING_LOAD_APP_SUCCESS:
            return {
                ...INITIAL_STATE,
                loadingApp: false,
                app: {
                    ...state.app,
                    ...(action.payload.app as TBookingReducer["app"]),
                },
                bookingFlow: action.payload.bookingFlow,
            };

        case types.BOOKING_LOAD_APP_FAILURE:
            return {
                ...state,
                loadingApp: false,
                error: {
                    orgNotFound: action.payload,
                },
            };

        case types.GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loadingCategories: true,
            };

        case types.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loadingCategories: false,
                categories: action.payload,
            };

        case types.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                loadingCategories: false,
                error: {
                    categoriesNotFound: action.payload,
                },
            };

        case types.GET_CATEGORY_SERVICES_REQUEST:
            return {
                ...state,
                loadingServices: true,
            };

        case types.GET_CATEGORY_SERVICES_SUCCESS:
            return {
                ...state,
                loadingServices: false,
                services: action.payload,
            };

        case types.GET_CATEGORY_SERVICES_FAILURE:
            return {
                ...state,
                loadingServices: false,
                error: action.payload,
            };

        case types.GET_SERVICE_INFO_REQUEST:
            return {
                ...state,
                loadingService: true,
            };

        case types.GET_SERVICE_INFO_SUCCESS:
            return {
                ...state,
                loadingService: false,
                serviceInfo: action.payload,
            };

        case types.GET_SERVICE_INFO_FAILURE:
            return {
                ...state,
                loadingService: false,
                serviceInfo: null,
                error: action.payload,
            };

        case types.GET_EMPLOYEES_IN_SERVICES_REQUEST:
            return {
                ...state,
                loadingEmployees: true,
            };

        case types.GET_EMPLOYEES_IN_SERVICES_SUCCESS:
            return {
                ...state,
                loadingEmployees: false,
                employees: action.payload,
            };

        case types.GET_EMPLOYEES_IN_SERVICES_FAILURE:
            return {
                ...state,
                loadingEmployees: false,
                error: action.payload,
            };

        case types.BOOKING_GET_QUICK_DATES_REQUEST:
            return {
                ...state,
                loadingQuickDates: true,
            };
        case types.BOOKING_GET_QUICK_DATES_SUCCESS:
            return {
                ...state,
                loadingQuickDates: false,
                quickDates: action.payload,
            };

        case types.BOOKING_GET_QUICK_DATES_FAILURE:
            return {
                ...state,
                loadingQuickDates: false,
                error: action.payload,
            };

        case types.BOOKING_GET_AVAILABLE_DATE_REQUEST:
            return {
                ...state,
                loadingAvailableDate: true,
            };
        case types.BOOKING_GET_AVAILABLE_DATE_SUCCESS:
            return {
                ...state,
                loadingAvailableDate: false,
                availableMonths: action.payload.availableMonths,
                availableDays: action.payload.availableDays,
            };

        case types.BOOKING_GET_AVAILABLE_DATE_FAILURE:
            return {
                ...state,
                loadingAvailableDate: false,
                error: action.payload,
            };

        case types.BOOKING_GET_AVAILABLE_SLOTS_REQUEST:
            return {
                ...state,
                loadingAvailableSlots: true,
            };
        case types.BOOKING_GET_AVAILABLE_SLOTS_SUCCESS:
            return {
                ...state,
                loadingAvailableSlots: false,
                availableSlots: action.payload.availableSlots,
                noPreferenceSlots: action.payload.noPreferenceSlots,
            };

        case types.BOOKING_GET_AVAILABLE_SLOTS_FAILURE:
            return {
                ...state,
                loadingAvailableSlots: false,
                error: action.payload,
            };

        case types.BOOKING_CREATE_BOOKING_REQUEST:
            return {
                ...state,
                submittingBooking: true,
            };
        case types.BOOKING_CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                submittingBooking: false,
            };

        case types.BOOKING_CREATE_BOOKING_FAILURE:
            return {
                ...state,
                error: action.payload,
                submittingBooking: false,
            };

        case types.BOOKING_CANCEL_BOOKING_REQUEST:
            return {
                ...state,
                loadingCancel: true,
            };
        case types.BOOKING_CANCEL_BOOKING_SUCCESS:
            return {
                ...state,
                loadingCancel: false,
            };

        case types.BOOKING_CANCEL_BOOKING_FAILURE:
            return {
                ...state,
                error: action.payload.cancelFailed,
                loadingCancel: false,
            };

        case types.BOOKING_GET_BOOKING_REQUEST:
            return {
                ...state,
                loadingAppointment: true,
            };

        case types.BOOKING_GET_BOOKING_SUCCESS:
            return {
                ...state,
                loadingAppointment: false,
                appointment: action.payload,
            };

        case types.BOOKING_GET_BOOKING_FAILURE:
            return {
                ...state,
                loadingAppointment: false,
                error: action.payload,
            };

        case types.BOOKING_RESCHEDULE_REQUEST:
            return {
                ...state,
                updatingBooking: true,
            };

        case types.BOOKING_RESCHEDULE_SUCCESS:
            return {
                ...state,
                updatingBooking: false,
                appointment: action.payload,
            };

        case types.BOOKING_RESCHEDULE_FAILURE:
            return {
                ...state,
                updatingBooking: false,
                error: action.payload,
            };

        case types.BOOKING_UPDATE_ENTITY_INFOS_REQUEST:
            return {
                ...state,
                updatingEntityInfos: true,
            };

        case types.BOOKING_UPDATE_ENTITY_INFOS_SUCCESS:
            return {
                ...state,
                updatingEntityInfos: false,
                appointment: {
                    ...state.appointment,
                    entityInfo: action.payload,
                },
            };

        case types.BOOKING_UPDATE_ENTITY_INFOS_FAILURE:
            return {
                ...state,
                updatingEntityInfos: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export const middleware = async (
    _state = INITIAL_STATE,
    action: TAction,
    dispatch: React.Dispatch<{ type: string; payload?: any }>
) => {
    /**
     * @description load app dependencies.
     */
    const bookingLoadApp = () => {
        // view appointment flow
        const pageUrl = window.location.pathname;

        try {
            const orgId = (() => {
                if (matchPath("/manage/:orgId/:appointmentId/*", pageUrl)) {
                    const params = matchPath("/manage/:orgId/:appointmentId/*", pageUrl);
                    return params?.params.orgId;
                }
                // book an appointment flow
                const params = matchPath({ path: "/:orgId/*" }, pageUrl);
                return params?.params.orgId;
            })();

            const appName = new URLSearchParams(window.location.search).get(SEARCH_PARAMS.name);
            const appFavicon = new URLSearchParams(window.location.search).get(SEARCH_PARAMS.favicon);

            if (appName) {
                const title = document.head.querySelector("title");
                if (title) title.innerText = `${appName} - Booking`;
            }

            if (appFavicon) {
                let link = document.head.querySelector('link[rel="icon"]');
                link?.setAttribute("href", atob(appFavicon));
            }

            if (!orgId) {
                throw new Error("Missing the organization");
            }

            // No bug related to someone navigating to a different orgId
            localStorage.removeItem(LOCAL_STORAGE_KEYS.orgId);
            localStorage.setItem(LOCAL_STORAGE_KEYS.orgId, orgId);

            setTimeout(() => {
                dispatch({
                    type: types.BOOKING_LOAD_APP_SUCCESS,
                    payload: {
                        app: {
                            appName,
                            appFavicon: appFavicon ? atob(appFavicon) : undefined,
                        },
                    },
                });
            }, 1000);
        } catch (error: any) {
            console.error(error);
            dispatch({ type: types.BOOKING_LOAD_APP_FAILURE, payload: error });
        }
    };

    /**
     * @description get all categories.
     */
    const bookingGetCategories = async () => {
        try {
            const { data } = await bookingClass.bookingGetCategories();

            // well if no categories it means there are no services at all.
            if (!data.length) {
                throw new Error("Looks like we do not have services to be booked right now");
            }

            dispatch({ type: types.GET_CATEGORIES_SUCCESS, payload: data });
        } catch (error: any) {
            console.error(error);
            dispatch({ type: types.GET_CATEGORIES_FAILURE, payload: error });
        }
    };

    /**
     * @description get services categories
     */
    const bookingGetCategoryServices = async () => {
        try {
            const { categoryId } = action.payload;
            if (!categoryId) throw new Error("Category is invalid.");
            const { data } = await bookingClass.bookingGetCategoryServices(action.payload);

            dispatch({ type: types.GET_CATEGORY_SERVICES_SUCCESS, payload: data });
        } catch (error: any) {
            if (error?.error?.code === 404) {
                return dispatch({ type: types.GET_CATEGORY_SERVICES_SUCCESS, payload: [] });
            }
            dispatch({ type: types.GET_CATEGORY_SERVICES_FAILURE, payload: error });
        }
    };

    const bookingGetServiceInfos = async () => {
        try {
            const { serviceId } = action.payload;
            if (!serviceId) throw new Error("Service is invalid.");
            const { data } = await bookingClass.bookingGetServiceInfos(action.payload);
            dispatch({ type: types.GET_SERVICE_INFO_SUCCESS, payload: data });
        } catch (error: any) {
            console.error(error);
            dispatch({ type: types.GET_SERVICE_INFO_FAILURE, payload: error });
        }
    };

    /**
     * @description get employees in a services
     */
    const getEmployeesInServices = async () => {
        try {
            const { data: employees } = await bookingClass.getEmployeesInServices({
                serviceId: action.payload.serviceId,
                locationId: action.payload.locationId,
            });

            dispatch({
                type: types.GET_EMPLOYEES_IN_SERVICES_SUCCESS,
                payload: employees,
            });
        } catch (error: any) {
            console.error(error);
            if (error?.error?.code === 404) {
                return dispatch({ type: types.GET_EMPLOYEES_IN_SERVICES_SUCCESS, payload: [] });
            }
            dispatch({ type: types.GET_EMPLOYEES_IN_SERVICES_FAILURE, payload: error });
        }
    };

    /**
     * @description get quick available dates
     */
    const bookingQuickDates = async () => {
        try {
            const { data }: { data: AvailableSlot[] } = await bookingClass.bookingGetQuickDates(action.payload);

            const dates = data
                .map((slot: AvailableSlot) => {
                    const now = moment();
                    if (moment(slot.start).isBefore(now, "minutes")) return;
                    return moment.tz(slot.start, BROWSER_TIMEZONE).format();
                })
                .filter(nonNullable)
                // find the first start time for each unique date
                // This will help for converting back to UTC when sending to the BE
                .reduce((acc: Record<string, string>, currentValue: string) => {
                    const date = moment(currentValue).format('YYYY-MM-DD');
                    if (!acc[date]) acc[date] = currentValue;
                    return acc;
                }, {});

            const availableDates = Object.values(dates).slice(0, 5); // Unique & max 5

            dispatch({
                type: types.BOOKING_GET_QUICK_DATES_SUCCESS,
                payload: availableDates,
            });
        } catch (error: any) {
            console.error(error);
            if (error?.error?.code === 404) {
                return dispatch({ type: types.BOOKING_GET_QUICK_DATES_SUCCESS, payload: [] });
            }
            dispatch({ type: types.BOOKING_GET_QUICK_DATES_FAILURE, payload: error });
        }
    };

    /**
     * @description get available date
     */
    const bookingGetAvailableDate = async () => {
        try {
            const { data } = await bookingClass.bookingGetMonthAvailableDays(action.payload);

            const dates = data.map((date: { start: string }) =>
                moment.tz(date.start, BROWSER_TIMEZONE).format("YYYY-MM-DD")
            );

            const availableDays = [...new Set(dates)];

            dispatch({
                type: types.BOOKING_GET_AVAILABLE_DATE_SUCCESS,
                payload: {
                    availableDays,
                },
            });
        } catch (error: any) {
            console.error(error);
            if (error?.error?.code === 404) {
                return dispatch({ type: types.BOOKING_GET_AVAILABLE_DATE_SUCCESS, payload: { availableDays: [] } });
            }
            dispatch({ type: types.BOOKING_GET_AVAILABLE_DATE_FAILURE, payload: error });
        }
    };

    /**
     * @description get available date
     */
    const bookingGetAvailableSlots = async () => {
        try {
            const { data: availableSlots } = await bookingClass.bookingGetDateAvailableSlots(action.payload);

            // BE returns extra slots just filtering them out
            // based on the provided end date on the payload.
            const filteredSlots = availableSlots.filter((slot: AvailableSlot) => {
                const now = moment();
                const end = moment(action.payload.end);
                const isPastTime = moment(slot.start).isBefore(now, "minutes");
                const isSameDate = moment(slot.start).isSame(end, "date");
                return isSameDate && !isPastTime;
            });

            dispatch({
                type: types.BOOKING_GET_AVAILABLE_SLOTS_SUCCESS,
                payload: { availableSlots: filteredSlots },
            });
        } catch (error: any) {
            console.error(error);
            if (error?.error?.code === 404) {
                return dispatch({
                    type: types.BOOKING_GET_AVAILABLE_SLOTS_SUCCESS,
                    payload: {
                        availableSlots: [],
                    },
                });
            }
            dispatch({ type: types.BOOKING_GET_AVAILABLE_SLOTS_FAILURE, payload: error });
        }
    };

    /**
     * @description create a booking
     */
    const bookingCreateBooking = async () => {
        try {
            const { data } = await bookingClass.bookingCreateBooking(action.payload);

            const { data: booking } = await bookingClass.bookingGetBooking({ appointmentId: data.id });

            dispatch({
                type: types.BOOKING_CHANGE_STATE,
                payload: {
                    appointment: booking,
                },
            });

            // redirect to view booking
            const linkParams = new URL(booking.link);
            dispatch(redirect({ path: linkParams.pathname }));
        } catch (error: any) {
            console.error(error);
            if (error.error?.code === 404) {
                dispatch({
                    type: types.BOOKING_CREATE_BOOKING_FAILURE,
                    payload: {
                        bookingFailed: "Sorry we are facing problems to retrieving appointments now, try again later.",
                    },
                });
            } else {
                dispatch({
                    type: types.BOOKING_CREATE_BOOKING_FAILURE,
                    payload: {
                        bookingFailed: "Sorry we are facing problems to book appointments now, try again later.",
                    },
                });
            }
        }
    };

    /**
     * @description cancel a booking
     */
    const bookingCancelBooking = async () => {
        try {
            await bookingClass.bookingCancelBooking(action.payload);

            dispatch({ type: types.BOOKING_CANCEL_BOOKING_SUCCESS });
            dispatch({ type: types.BOOKING_CHANGE_STATE, payload: { appointment: null } });

            dispatch(redirect({ path: "success" }));
        } catch (error: any) {
            console.error(error);
            dispatch({
                type: types.BOOKING_CANCEL_BOOKING_FAILURE,
            });
        }
    };

    /**
     * @description get a booking
     */
    const bookingGetBooking = async () => {
        try {
            const { data } = await bookingClass.bookingGetBooking(action.payload);
            dispatch({
                type: types.BOOKING_GET_BOOKING_SUCCESS,
                payload: data,
            });
        } catch (error: any) {
            console.error(error);
            if (error?.error?.code === 404) {
                return dispatch({
                    type: types.BOOKING_GET_BOOKING_FAILURE,
                    payload: {
                        appointmentNotFound: true,
                    },
                });
            }
            dispatch({
                type: types.BOOKING_GET_BOOKING_FAILURE,
                payload: {
                    bookingFailed: "Sorry we are facing problems getting the appointment.",
                },
            });
        }
    };

    /**
     * @description update booking infos
     */
    const bookingReschedule = async () => {
        try {
            const { data } = await bookingClass.bookingReschedule(action.payload);

            dispatch({ type: types.BOOKING_RESCHEDULE_SUCCESS, payload: data });

            dispatch(redirect({ path: "success" }));
        } catch (error: any) {
            console.error(error);
            if (error.error?.code === 404) {
                dispatch({
                    type: types.BOOKING_RESCHEDULE_FAILURE,
                    payload: {
                        bookingFailed:
                            apiV2.stringifyErrors(error) ||
                            "Sorry we are facing problems to book appointments now, try again later.",
                    },
                });
            } else {
                dispatch({
                    type: types.BOOKING_RESCHEDULE_FAILURE,
                    payload: {
                        bookingFailed: "Sorry we are facing problems to book appointments now, try again later.",
                    },
                });
            }
        }
    };

    /**
     * @description update entity infos
     */
    const bookingUpdateEntityInfos = async () => {
        try {
            const { data: entityInfo } = await bookingClass.bookingUpdateEntityInfos(action.payload);
            dispatch({
                type: types.BOOKING_UPDATE_ENTITY_INFOS_SUCCESS,
                payload: entityInfo,
            });
        } catch (error: any) {
            console.error(error);
            if (error.error?.code) {
                dispatch({
                    type: types.BOOKING_UPDATE_ENTITY_INFOS_FAILURE,
                    payload: {
                        bookingFailed:
                            apiV2.stringifyErrors(error) ||
                            "Sorry we are facing problems to update appointments now, try again later.",
                    },
                });
            } else {
                dispatch({
                    type: types.BOOKING_UPDATE_ENTITY_INFOS_FAILURE,
                    payload: { error },
                });
            }
        }
    };

    switch (action.type) {
        case types.BOOKING_LOAD_APP_REQUEST:
            bookingLoadApp();
            break;

        case types.GET_CATEGORIES_REQUEST:
            await bookingGetCategories();
            break;

        case types.GET_CATEGORY_SERVICES_REQUEST:
            await bookingGetCategoryServices();
            break;

        case types.GET_SERVICE_INFO_REQUEST:
            await bookingGetServiceInfos();
            break;

        case types.GET_EMPLOYEES_IN_SERVICES_REQUEST:
            await getEmployeesInServices();
            break;

        case types.BOOKING_GET_QUICK_DATES_REQUEST:
            await bookingQuickDates();
            break;

        case types.BOOKING_GET_AVAILABLE_DATE_REQUEST:
            await bookingGetAvailableDate();
            break;

        case types.BOOKING_GET_AVAILABLE_SLOTS_REQUEST:
            await bookingGetAvailableSlots();
            break;

        case types.BOOKING_CREATE_BOOKING_REQUEST:
            await bookingCreateBooking();
            break;

        case types.BOOKING_CANCEL_BOOKING_REQUEST:
            await bookingCancelBooking();
            break;

        case types.BOOKING_GET_BOOKING_REQUEST:
            await bookingGetBooking();
            break;

        case types.BOOKING_RESCHEDULE_REQUEST:
            await bookingReschedule();
            break;

        case types.BOOKING_UPDATE_ENTITY_INFOS_REQUEST:
            await bookingUpdateEntityInfos();
            break;
        default:
            break;
    }
};
