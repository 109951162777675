import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { bookingChangeState, bookingGetBooking, bookingReschedule } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { ConfirmReschedule } from "@/components/confirm-reschedule/ConfirmReschedule";
import DatePicker from "@/components/date-picker/DatePicker";
import ActionFooter from "@/components/footers/actions";
import QuickDates from "@/components/quick-dates/QuickDates";
import { Success } from "@/components/success/Success";
import TimePicker from "@/components/time-picker/TimePicker";
import { SEARCH_PARAMS } from "@/helpers/constants";
import { getEntityInfo } from "@/helpers/helpers";

/** Page used to manage appointments */
const RescheduleAppointment = () => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, updatingBooking, redirectTo } = state;

    const [disableForward, setDisableForward] = useState(true);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { appointmentId } = useParams();

    const isCalendar = useMatch("/manage/:orgId/:appointmentId/reschedule/calendar");
    const isQuickDates = useMatch("/manage/:orgId/:appointmentId/reschedule");
    const isSlots = useMatch("/manage/:orgId/:appointmentId/reschedule/slots");
    const isConfirm = useMatch("/manage/:orgId/:appointmentId/reschedule/confirm");
    const isRescheduleFlow = useMatch("/manage/:orgId/:appointmentId/reschedule/success");

    useEffect(() => {
        const selectedDate = searchParams.get(SEARCH_PARAMS.date);
        const start = searchParams.get(SEARCH_PARAMS.start);
        const end = searchParams.get(SEARCH_PARAMS.end);

        if (isSlots) {
            if (start && end) return setDisableForward(false);
            return setDisableForward(true);
        }

        if (isCalendar || isQuickDates) {
            if (selectedDate && moment(selectedDate).isValid()) return setDisableForward(false);
            return setDisableForward(true);
        }

        if (isConfirm) {
            if (!selectedDate || !start || !end) return setDisableForward(true);
            return setDisableForward(false);
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        if (!redirectTo) return;
        navigate(redirectTo, { replace: true });
        dispatch(bookingChangeState({ redirectTo: null }));
    }, [redirectTo]);

    // Doing it like this will ignore searchParams change and really go back
    // in the route history without going back in the searchParams change only
    const handleBackward = () => {
        if (isConfirm) return navigate(`../slots?${searchParams.toString()}`, { relative: "path" });
        navigate("..", { relative: "path" });
    };

    const handleForward = () => {
        if (isCalendar || isQuickDates) {
            navigate(`slots?${searchParams.toString()}`);
        }
        if (isSlots) {
            navigate(`confirm?${searchParams.toString()}`);
        }

        if (isConfirm) {
            const start = searchParams.get(SEARCH_PARAMS.start);
            const end = searchParams.get(SEARCH_PARAMS.end);

            if (!appointment || !start || !end) return;

            dispatch(
                bookingReschedule({
                    appointmentId: appointment?.id,
                    data: {
                        entityInfo: getEntityInfo(appointment?.entityInfo),
                        locationId: appointment.locationId,
                        serviceId: appointment.serviceId,
                        userId: appointment.userId,
                        start,
                        end,
                    },
                })
            );
        }
    };

    useEffect(() => {
        if (!appointment && appointmentId) {
            dispatch(
                bookingGetBooking({
                    appointmentId: window.atob(appointmentId),
                })
            );
        }
    }, [appointment]);

    return (
        <>
            <Routes>
                <Route path="/" element={<QuickDates />} />
                <Route path="/calendar" element={<DatePicker />} />
                <Route path="/slots" element={<TimePicker />} />
                <Route path="/confirm" element={<ConfirmReschedule />} />
                <Route path="/success" element={<Success />} />
            </Routes>
            {!isRescheduleFlow && (
                <ActionFooter
                    actions={{
                        backward: { onClick: handleBackward },
                        forward: {
                            onClick: handleForward,
                            // Undefined so we get the Move Forward aria label
                            text: isConfirm ? "Reschedule" : undefined,
                            disabled: disableForward,
                            loading: updatingBooking,
                        },
                    }}
                />
            )}
        </>
    );
};

export default RescheduleAppointment;
