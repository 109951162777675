import { SkeletonLoader } from "@soltivo/draw-a-line";
import styles from "./Item.module.scss";

interface ItemSkeletonProps {
    avatar?: boolean;
    contentDirection?: "row" | "column";
    width?: "small" | "medium" | "full";
    align?: "center" | "start";
    description?: boolean;
}

const ItemSkeleton: React.FC<ItemSkeletonProps> = ({
    align,
    avatar,
    contentDirection,
    description,
    width = "full",
}) => (
    <div
        aria-label="Skeleton loader"
        role="listitem"
        aria-busy="true"
        className={`${styles.item} ${styles[width]} ${styles.skeleton}`}
    >
        <div className={styles.label} style={{ flexDirection: contentDirection, justifyContent: align }}>
            {avatar && <SkeletonLoader style={{ paddingRight: 6 }} type="round" height={32} width={32} />}
            <SkeletonLoader className={styles.title} height={24} />
            {description && <SkeletonLoader className={styles.description} height={24} width={152} />}
        </div>
    </div>
);

export default ItemSkeleton;
