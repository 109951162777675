import { ReactNode } from "react";
import { SkeletonLoader } from "@soltivo/draw-a-line";
import styles from "./InfoItem.module.scss";

interface InfoItemProps {
    title: string;
    description: ReactNode;
    loader?: {
        loading: boolean;
        width: number;
    };
}
const InfoItem = ({ title, description, loader }: InfoItemProps) => {
    return (
        <div
            role="listitem"
            title={title}
            aria-busy={loader?.loading}
            aria-label={`${title}${loader?.loading ? " loading" : ""}`}
            className={styles.info_item}
        >
            <h4>{title}</h4>
            <span>{loader?.loading ? <SkeletonLoader height={16} width={loader.width} /> : description}</span>
        </div>
    );
};

export default InfoItem;
