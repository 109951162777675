import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@soltivo/draw-a-line";
import Illustration from "@soltivo/draw-a-line/core/assets/illutrations/SteveSad.svg";
import styles from "./page.module.scss";

const AppointmentNotFound = () => {
    const { orgId } = useParams();

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/${orgId}`, { replace: true });
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.illustration_container}>
                    <img height="300px" src={Illustration} aria-label="Steve Sorry hands" />
                </div>
                <h1 className={styles.title}>Appointment Not Found.</h1>
                <h6 className={styles.description}>Double check the link to make sure it's the right one</h6>
                <Button onClick={handleClick} aria-label="Book an Appointment" variant="bluemine-900">
                    Book an Appointment
                </Button>
            </div>
        </div>
    );
};

export default AppointmentNotFound;
