import { FC, memo } from "react";
import { AvailableSlot } from "@/types";
import { MOMENT_FORMAT } from "@/helpers/constants";
import moment from "moment-timezone";
import Item from "../item/Item";

interface TimeItemProps {
    onSelectSlot: (slot: AvailableSlot) => void;
    selectedSlot?: string;
    slot: AvailableSlot;
}

const TimeItem: FC<TimeItemProps> = memo(({ slot, selectedSlot, onSelectSlot }) => {
    const selected = selectedSlot === slot.start + slot.end;
    return (
        <Item
            width="small"
            title={moment(slot.start).format(MOMENT_FORMAT.time)}
            selected={selected}
            onSelect={() => onSelectSlot(slot)}
            id={slot.start + slot.end}
        />
    );
});

export default TimeItem;
