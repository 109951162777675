import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CalendarPicker, { CalendarPickerProps } from "@/components/calendar/Calendar";
import { bookingChangeState, bookingGetAvailableDate } from "@/reducer/actions";
import { BookingContext } from "@/reducer/booking.context";
import { MOMENT_FORMAT, SEARCH_PARAMS } from "@/helpers/constants";
import Header from "../header/Header";
import styles from "./DatePicker.module.scss";

interface DateProps {
    setCanMoveForward?: React.Dispatch<React.SetStateAction<boolean>>;
}
const DatePicker = (props: DateProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { appointment, availableDays, loadingAvailableDate } = state;
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = searchParams.get(SEARCH_PARAMS.employee) || appointment?.userId;
    const serviceId = searchParams.get(SEARCH_PARAMS.service) || appointment?.serviceId;
    const locationId = searchParams.get(SEARCH_PARAMS.location) || appointment?.locationId;

    const date = searchParams.get(SEARCH_PARAMS.date);

    useEffect(() => {
        if (userId && serviceId && locationId) {
            dispatch(
                bookingGetAvailableDate({
                    start: moment().utc().format(MOMENT_FORMAT.iso),
                    end: moment().endOf("month").utc().format(MOMENT_FORMAT.iso),
                    userId: userId,
                    serviceId: serviceId,
                    locationId: locationId,
                })
            );
        }

        // Auto select the populated date from the search params
        if (date && moment(date).isValid()) {
            handleSelectDate(moment(date).valueOf());
        }

        return () => dispatch(bookingChangeState({ availableDays: [] }));
    }, []);

    const handleFetchMoreDates: CalendarPickerProps["fetchDates"] = (date) => {
        if (!userId || !serviceId || !locationId) return;

        const now = moment();

        // Don't fetch past month/dates
        if (moment(date).isBefore(now, "month")) return;

        const start = (() => {
            if (moment(date).isSameOrBefore(now, "month")) return moment().utc();
            return moment(date).startOf("month").utc();
        })();

        dispatch(
            bookingGetAvailableDate({
                start: start.format(MOMENT_FORMAT.iso),
                end: moment(date).endOf("month").add(6, "days").utc().format(MOMENT_FORMAT.iso),
                userId: userId,
                serviceId: serviceId,
                locationId: locationId,
            })
        );
    };

    const handleSelectDate: CalendarPickerProps["onChange"] = (event) => {
        const selected = moment(event).format(MOMENT_FORMAT.iso);

        props.setCanMoveForward && props.setCanMoveForward(true);

        // Only re-update the search params if the date
        // actually has changed to avoid any re-render and other logic change
        if (searchParams.get(SEARCH_PARAMS.date) !== selected) {
            setSearchParams((prev) => {
                prev.delete(SEARCH_PARAMS.start);
                prev.delete(SEARCH_PARAMS.end);
                prev.set(SEARCH_PARAMS.date, selected);
                return prev.toString();
            });
        }
    };

    return (
        <div className={styles.calendarPicker_container}>
            <Header title="Select a Date" description="Please select the best date for you." />
            <CalendarPicker
                preselectedDate={searchParams.get(SEARCH_PARAMS.date) || ""}
                dates={availableDays.map((d) => moment(d).valueOf())}
                loading={loadingAvailableDate}
                onChange={handleSelectDate}
                fetchDates={handleFetchMoreDates}
            />
        </div>
    );
};

export default DatePicker;
