export default {
    CHANGE_FORM: "CHANGE_FORM",

    BOOKING_CHANGE_STATE: "BOOKING_CHANGE_STATE",

    BOOKING_LOAD_APP_REQUEST: "BOOKING_LOAD_APP_REQUEST",
    BOOKING_LOAD_APP_SUCCESS: "BOOKING_LOAD_APP_SUCCESS",
    BOOKING_LOAD_APP_FAILURE: "BOOKING_LOAD_APP_FAILURE",

    MOVE_TO_STEP_REQUEST: "NEXT_STEP_REQUEST",
    
    REDIRECT_REQUEST: "REDIRECT_REQUEST",

    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",

    GET_CATEGORY_SERVICES_REQUEST: "GET_CATEGORY_SERVICES_REQUEST",
    GET_CATEGORY_SERVICES_SUCCESS: "GET_CATEGORY_SERVICES_SUCCESS",
    GET_CATEGORY_SERVICES_FAILURE: "GET_CATEGORY_SERVICES_FAILURE",

    GET_SERVICE_INFO_REQUEST: "GET_SERVICE_INFO_REQUEST",
    GET_SERVICE_INFO_SUCCESS: "GET_SERVICE_INFO_SUCCESS",
    GET_SERVICE_INFO_FAILURE: "GET_SERVICE_INFO_FAILURE",

    GET_EMPLOYEES_IN_SERVICES_REQUEST: "GET_EMPLOYEES_IN_SERVICES_REQUEST",
    GET_EMPLOYEES_IN_SERVICES_SUCCESS: "GET_EMPLOYEES_IN_SERVICES_SUCCESS",
    GET_EMPLOYEES_IN_SERVICES_FAILURE: "GET_EMPLOYEES_IN_SERVICES_FAILURE",

    BOOKING_GET_QUICK_DATES_REQUEST: "BOOKING_GET_QUICK_DATES_REQUEST",
    BOOKING_GET_QUICK_DATES_SUCCESS: "BOOKING_GET_QUICK_DATES_SUCCESS",
    BOOKING_GET_QUICK_DATES_FAILURE: "BOOKING_GET_QUICK_DATES_FAILURE",

    BOOKING_GET_AVAILABLE_DATE_REQUEST: "BOOKING_GET_AVAILABLE_DATE_REQUEST",
    BOOKING_GET_AVAILABLE_DATE_SUCCESS: "BOOKING_GET_AVAILABLE_DATE_SUCCESS",
    BOOKING_GET_AVAILABLE_DATE_FAILURE: "BOOKING_GET_AVAILABLE_DATE_FAILURE",

    BOOKING_GET_AVAILABLE_SLOTS_REQUEST: "BOOKING_GET_AVAILABLE_SLOTS_REQUEST",
    BOOKING_GET_AVAILABLE_SLOTS_SUCCESS: "BOOKING_GET_AVAILABLE_SLOTS_SUCCESS",
    BOOKING_GET_AVAILABLE_SLOTS_FAILURE: "BOOKING_GET_AVAILABLE_SLOTS_FAILURE",

    BOOKING_CREATE_BOOKING_REQUEST: "BOOKING_CREATE_BOOKING_REQUEST",
    BOOKING_CREATE_BOOKING_SUCCESS: "BOOKING_CREATE_BOOKING_SUCCESS",
    BOOKING_CREATE_BOOKING_FAILURE: "BOOKING_CREATE_BOOKING_FAILURE",

    BOOKING_CANCEL_BOOKING_REQUEST: "BOOKING_CANCEL_BOOKING_REQUEST",
    BOOKING_CANCEL_BOOKING_SUCCESS: "BOOKING_CANCEL_BOOKING_SUCCESS",
    BOOKING_CANCEL_BOOKING_FAILURE: "BOOKING_CANCEL_BOOKING_FAILURE",

    BOOKING_GET_BOOKING_REQUEST: "BOOKING_GET_BOOKING_REQUEST",
    BOOKING_GET_BOOKING_SUCCESS: "BOOKING_GET_BOOKING_SUCCESS",
    BOOKING_GET_BOOKING_FAILURE: "BOOKING_GET_BOOKING_FAILURE",

    BOOKING_UPDATE_ENTITY_INFOS_REQUEST: "BOOKING_UPDATE_ENTITY_INFOS_REQUEST",
    BOOKING_UPDATE_ENTITY_INFOS_SUCCESS: "BOOKING_UPDATE_ENTITY_INFOS_SUCCESS",
    BOOKING_UPDATE_ENTITY_INFOS_FAILURE: "BOOKING_UPDATE_ENTITY_INFOS_FAILURE",

    BOOKING_RESCHEDULE_REQUEST: "BOOKING_RESCHEDULE_REQUEST",
    BOOKING_RESCHEDULE_SUCCESS: "BOOKING_RESCHEDULE_SUCCESS",
    BOOKING_RESCHEDULE_FAILURE: "BOOKING_RESCHEDULE_FAILURE",
};
