import { PropsWithChildren, memo } from "react";
import styles from "./Item.module.scss";

interface ItemProps {
    id: string;
    title?: string;
    description?: string;
    labelStyle?: React.CSSProperties;
    border?: boolean;
    width?: "small" | "medium" | "full";
    onSelect: (id: string) => void;
    selected: boolean;
}

const Item: React.FC<PropsWithChildren<ItemProps>> = memo(
    ({ id, onSelect, selected, border = true, width = "full", description, title, children, labelStyle }) => {
        return (
            <div
                role="listitem"
                aria-label={`${title} container`}
                className={`${styles.item} ${styles[width]} ${border ? styles.border : ""} ${
                    selected ? "selected" : ""
                } item`}
                onClick={() => onSelect(id)}
            >
                <input
                    id={id}
                    name="option"
                    onChange={() => onSelect(id)}
                    type="radio"
                    value={title}
                    checked={selected}
                    aria-label={`${title}${description ? ` - ${description}` : ""}`}
                    aria-owns={id}
                    aria-checked={selected}
                />
                <label style={labelStyle} htmlFor={id} className={styles.label}>
                    {children ? (
                        children
                    ) : (
                        <>
                            <span className={styles.title}>{title || "-"}</span>
                            {description && <span className={styles.description}>{description}</span>}
                        </>
                    )}
                </label>
            </div>
        );
    }
);

export default Item;
