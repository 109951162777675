import { useContext, useEffect, useState } from "react";
import { bookingChangeState, bookingGetServiceInfos } from "../../reducer/actions";
import { BookingContext } from "../../reducer/booking.context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "@/helpers/constants";
import { TServiceInfo } from "@/types";
import LocationItem from "@/components/location-item/LocationItem";
import ItemSkeleton from "@/components/item/ItemSkeleton";
import Header from "@/components/header/Header";
import ItemList from "../item-list/ItemList";

interface InnerListProps {
    serviceInfo: TServiceInfo | null;
    selected?: string;
    loadingService: boolean;
    onSelect: (id?: string) => void;
}

function InnerList(props: InnerListProps) {
    if (props?.loadingService) return [1, 2].map((idx) => <ItemSkeleton key={idx} description />);
    return props.serviceInfo?.locations.map((location) => (
        <LocationItem
            key={location.id}
            location={location}
            selected={props.selected === location.id}
            onSelect={props.onSelect}
        />
    ));
}

interface LocationsProps {
    setCanMoveForward: React.Dispatch<React.SetStateAction<boolean>>;
}
const Locations = (props: LocationsProps) => {
    const { state, dispatch } = useContext(BookingContext);
    const { app, loadingService, serviceInfo } = state;

    const [selectedLocation, setSelectedLocation] = useState<string>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const locationId = searchParams.get(SEARCH_PARAMS.location);
    const serviceId = searchParams.get(SEARCH_PARAMS.service);

    const onSelect = (locationId?: string) => {
        setSelectedLocation(locationId);

        if (!locationId) return;

        props.setCanMoveForward(true);

        // Only re-update the search params if the location id
        // actually has changed to avoid any re-render and other logic change
        if (searchParams.get(SEARCH_PARAMS.location) !== locationId) {
            setSearchParams((prev) => {
                // Employee listing depends on the location
                // so we must remove it from state
                prev.delete(SEARCH_PARAMS.employee);
                prev.set(SEARCH_PARAMS.location, locationId || "");
                return prev.toString();
            });
        }
    };

    useEffect(() => {
        return () => dispatch(bookingChangeState({ serviceInfo: null }));
    }, []);

    useEffect(() => {
        if (!serviceId) return navigate("../");
        dispatch(bookingGetServiceInfos({ serviceId }));
    }, [app]);

    useEffect(() => {
        // Better UX, auto select the only available option
        if (serviceInfo?.locations?.length === 1) {
            onSelect(serviceInfo.locations[0].id);
        }
        if (serviceInfo && locationId) {
            onSelect(serviceInfo?.locations.find((l) => l.id === locationId)?.id);
        }
    }, [serviceInfo?.locations]);

    return (
        <div>
            <Header title="Our Locations" description="Please select the nearest location from you." />
            <ItemList id="locations_list" loading={loadingService} selected={selectedLocation}>
                <InnerList
                    serviceInfo={serviceInfo}
                    onSelect={onSelect}
                    loadingService={loadingService}
                    selected={selectedLocation}
                />
            </ItemList>
        </div>
    );
};

export default Locations;
