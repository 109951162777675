import UserIconSVG from "../icons/UserIcon";
import styles from "./Avatar.module.scss";

interface AvatarProps {
    img?: {
        src: string;
        /** Name of the employee / user */
        name: string;
    };
}

const Avatar: React.FC<AvatarProps> = ({ img }) => {
    return (
        <div className={styles.avatar}>
            <div className={styles.image}>
                {img ? (
                    <img src={img.src} aria-label="Employee profile picture" />
                ) : (
                    // null
                    <UserIconSVG role="img" aria-label="Default employee avatar" />
                )}
            </div>
        </div>
    );
};

export default Avatar;
